import Axios from 'axios';
import React, { useState } from 'react';
import * as Constants from '../../assets/helpers/constants'
import Swal from 'sweetalert2'
import Player from './player'
import UserImg from '../../assets/images/User.png'
import MailImg from '../../assets/images/Mail.png'
import PhoneImg from '../../assets/images/Phone.png'

const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class DemoPlayer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collectDetails: false,
            url: null,
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            mailErr: null,
            isDisabled: false
        }
    }

    componentDidMount() {
        window.addEventListener("message", function (e) {
            e.preventDefault()
            console.log("Received message in Demo ClassRoom", e)
            if (e.data && e.data.includes("LESSON_COMPLETED")) {
                console.log("Lesson_Completed Action triggered in Demo Classroom")
                // window.parent?.postMessage('LESSON_COMPLETED', "http://localhost:3001");
                window.parent?.postMessage(e.data, Constants.WebSiteUrl);
                window.parent?.postMessage(e.data, Constants.WebSiteUrl2);
            }

            if (e.data === "LESSON_TERMINATED") {
                console.log("Lesson_Terminated Action triggered in Demo Classroom")
                // window.parent?.postMessage('LESSON_COMPLETED', "http://localhost:3001");
                window.parent?.postMessage('LESSON_TERMINATED', Constants.WebSiteUrl);
                window.parent?.postMessage('LESSON_TERMINATED', Constants.WebSiteUrl2);
            }
        })

        const urlParams = window.location.href
        let values = urlParams.split("url=")
        console.log("URL", values[1])
        let collectedDetails = false

        try {
            if (window.localStorage.getItem("collectedDetails") !== null) {
                collectedDetails = window.localStorage['collectedDetails'];
            }
            this.setState({
                url: values[1],
                collectDetails: collectedDetails
            })
        } catch (e) {
            this.setState({
                url: values[1],
                collectDetails: collectedDetails
            })
        }


    }

    changeValue = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    submitDetails = (event) => {
        event.preventDefault();

        const urlParams = window.location.href
        let values = urlParams.split("utm_source=")

        this.setState({
            isDisabled: true,
        }, () => {

            if(this.state.firstName.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'First Name is Mandatory',
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isDisabled: false
            })
            return
        }
        if (this.state.email.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Email is Mandatory',
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isDisabled: false
            })
            return
        }
        if (this.state.phoneNo.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Phone Number is Mandatory',
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isDisabled: false
            })
            return
        }

        if (this.state.phoneNo.length < 8 || this.state.phoneNo.length > 12) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Phone No. must be greater than 8 & less than 12 digits',
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isDisabled: false
            })
            return
        }

        if (regEx.test(this.state.email) === false) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please enter a valid email!',
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isDisabled: false
            })
            return
        }

        let data = {
            name: this.state.firstName + " " + this.state.lastName,
            email: this.state.email,
            phone: this.state.phoneNo,
            source: "Unassisted-website",
            srcParam: values[1]
        }
        Axios.post(Constants.BASE_URL + "api/submitUserDetails", data).then((result) => {
            if (result.data.status) {
                this.setState({
                    collectDetails: true
                }, () => {
                    try {
                        window.localStorage['collectedDetails'] = true
                    } catch (e) {
                        console.log(e)
                    }
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Please try again',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.setState({
                    isDisabled: false
                })
            }
        });
    })


}

render() {
    return (
        <React.Fragment>
            {!this.state.collectDetails && <div className='captureDetails'>
                <div className="contact-form-wrapper-active">
                    <form className="contact-form" onSubmit={this.submitDetails}>
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <img src={UserImg} className="user-icon" alt="user" />
                                <input id="fname" className="pl-0" placeholder="Name" type="text" minLength="3" maxLength="50" onChange={(e) => this.changeValue("firstName", e)} required />
                            </div>
                        </div>
                        <img src={MailImg} className="mail-icon" alt="mail" />
                        <input id="emailId" placeholder="Email" type="email" minLength="8" maxLength="50" onChange={(e) => this.changeValue("email", e)} required />
                        <img src={PhoneImg} className="phone-icon .img-fluid" alt="phone" />
                        <input id="phone" placeholder="Phone" type="number" onChange={(e) => this.changeValue("phoneNo", e)} required />
                        <div className="contact-btn">
                            <button className="btn" type="submit" disabled={this.state.isDisabled}>Submit</button>
                        </div>
                    </form >
                    {this.state.mailErr && (
                        <span className='mail-error'>{this.state.mailErr}</span>
                    )}
                </div >
                <div className='fill-details'>
                    <h1>Please Fill in Your Details</h1>
                </div>
            </div >
            }
            {
                this.state.collectDetails &&
                <Player url={this.state.url} type='onlyPlayer' />
            }
        </React.Fragment >
    )
}
}

export default DemoPlayer