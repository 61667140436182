import React from 'react';
import Loader from "react-loader-spinner";

const LoaderComp = (props) => {
    return (
        <div>
            {props.isLoading ?
                (
                    <div className="loader">
                        <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={80}
                            width={80}
                        />
                    </div>
                ) : null
            }
        </div>
    )
}

export default LoaderComp
