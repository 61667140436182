import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import PreViewLessonModal from "./previewLessonModal";
import searchIcon from '../../../assets/images/searchIcon.png';
import UploadLessonModal from "../backlogs/uploadLessonModal";


function Status(props) {
    const [lessonStatus, setLessonStatus] = useState('');
    const [showModal, setShowModa] = useState(false)
    const [lessonId, setLessonId] = useState('');
    const [dataFromApi, setDataFromApi] = useState('')
    const [isUpload, setIsUpload] = useState(false);
    const [uploadingLessonId, setUploadingLessonId] = useState('')
    const [file, setFile] = useState(null);


    const getStatus = () => {
        Axios.get(Constants.BASE_URL + `api/getTheStatusOfLessons/${props.userId}`).then((result) => {
            if (result.data.status) {

                setLessonStatus(result.data.info);
                setDataFromApi(result.data.info);

            } else {
                setLessonStatus([]);
                setDataFromApi([]);
            }
        })
    }

    //search Status
    const filterData = (e) => {
        if (e.target.value == '') {
            setLessonStatus(dataFromApi)
        } else {
            const filteredResult = dataFromApi.filter((item) => {

                return item.Lesson.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Subject.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Class.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Chapter.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Status.toLowerCase().includes(e.target.value.toLowerCase());

            });
            setLessonStatus(filteredResult);
        }
    }

    //File upload
    const fileUpload = (e) => {

        e.preventDefault();
        setFile(e.target.files[0]);
    }

    //Submit the uploaded file
    const submitFile = () => {

        const extension = file.name.split(".").pop();

        if (extension !== 'exp') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only .exp file",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }


        const fromData = new FormData();
        fromData.append("fileUpload", file);
        fromData.append("lessonId", uploadingLessonId);


        Axios.post(Constants.BASE_URL + "api/uploadLesson", fromData).then((result) => {
            if (result.data.status) {
                setIsUpload(false);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "File Updated succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                localStorage.removeItem("details");
    
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Error while Uploading file",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    useEffect(() => {
        getStatus();
        const interval = setInterval(() => { getStatus() }, 30000);
        return () => clearInterval(interval)
    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Class",
            center: true,
            selector: (row) => row.Class,
            grow: 1
        },
        {
            name: "Subject",
            center: true,
            selector: (row) => row.Subject,
            grow: 1
        },
        {
            name: "Chapter",
            center: true,
            selector: (row) => row.Chapter,
            grow: 1
        },
        {
            name: "Lesson",
            center: true,
            selector: (row) => row.Lesson,
            grow: 1
        },
        {
            name: "Status",
            center: true,
            grow: 2,
            cell: row => {
                return (
                    <div className="actions">
                        {
                            <label className={row.Status === 'Accepted' ? '' : ''} >{row.Status}</label>
                        }
    
                    </div>
                )
            }
        },
        {
            name: "Preview",
            center: true,
            grow: 1,
            cell: (row) => <button className="btn-action preview" type="button" onClick={() => togglePreviewPopup(row.Id)}>Play</button>
        },
        {
            name: "Action",
            center: true,
            grow: 1,
            cell: (row) => row.Status === "Review Pending" ? <button className="btn re-submit-btn" onClick={() => toggleFileUploadPopup(row.Id)}>Re-submit</button> : ""
        }
        
    ]

    const togglePreviewPopup = (rowId) => {
        setShowModa(!showModal);
        setLessonId(rowId);
    }
    const toggleFileUploadPopup = (id) =>{
        setIsUpload(!isUpload)
        setUploadingLessonId(id)
    }


    return (
        <div className="main-content">
            <UploadLessonModal show={isUpload} onHide={toggleFileUploadPopup} upload={fileUpload} formSubmit={submitFile} />
            <PreViewLessonModal show={showModal} onHide={togglePreviewPopup} lessonID={lessonId} />
            <Header title="Status" {...props} />
            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        <div className="col-7">
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="search-bar preview-lesson">
                                <input className="search" placeholder="Search" onChange={filterData} />
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <DataTable
                    columns={columns}
                    data={lessonStatus}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    persistTableHead
                    noDataComponent
                />
            </div>

        </div>
    )
}
export default Status;