import React from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import * as Constants from '../../assets/helpers/constants'
import LoaderComp from '../Loader'
import Swal from 'sweetalert2'
import CryptoJS from 'crypto-js'

const secret = 'SecretKeyToken'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            rememberme: false,
            isLoading: false
        }
    }

    changeValue = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    submitForm = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        }, () => {
            if (this.state.username.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'UserName is Mandatory',
                    showConfirmButton: false,
                    timer: 2000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.password.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Password is Mandatory',
                    showConfirmButton: false,
                    timer: 2000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            

            let data = {
                username: this.state.username,
                password: this.state.password
            }

            Axios.post(Constants.BASE_URL + "api/Login", data).then((result) => {
                if (result.data.status) {
                    localStorage['userDetails'] = JSON.stringify(result.data.info)
                    localStorage["rememberme"] = this.state.rememberme
                    
                    if (this.state.rememberme) {
                        var encryptedPwd = CryptoJS.AES.encrypt(this.state.password, secret).toString();
                        localStorage.setItem("uid", this.state.username)
                        localStorage.setItem('signInData', encryptedPwd)
                    }

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Loggedin Successfully',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    }, () => {
                        // this.props.inactivityTime();
                        this.props.history.push('/Contents');
                    })
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Invalid Credentials',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                }
            });
        })
    }

    getSignInData = () =>{
        let uName = '';
        let encryptedPwd = '';
        if (localStorage.getItem('uid') !== null || localStorage.getItem('signInData') !== null){
            uName = localStorage.getItem('uid');
            encryptedPwd = localStorage.getItem('signInData');
        }
       
        if(uName !== undefined || encryptedPwd !== undefined){

            var bytes = CryptoJS.AES.decrypt(encryptedPwd, secret);
            var pwd = bytes.toString(CryptoJS.enc.Utf8);

            this.setState({
                username: uName,
                password: pwd
            })
        }

    }

    componentDidMount = () =>{
        this.getSignInData()
    }

    handleChange = (e) => {
        this.setState({
            rememberme: e.target.checked
        })
    }

    render() {
        return (
            <div className="loginScreen text-center commonSection">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="welcomeText">
                    Welcome
                </div>
                <div className="login">
                    <p className="signInText">Sign In</p>
                    <form className="loginForm leftAlign" onSubmit={this.submitForm}>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor='email' className='inputLabels'>User Name</label><br />
                                <input type="email" id='email' className="inputFields noOutline noBorder" value={this.state.username} onChange={(e) => this.changeValue("username", e)} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor='password' className='inputLabels' >Password</label><br />
                                <input type="password" id="password" className="inputFields noOutline noBorder" value={this.state.password} onChange={(e) => this.changeValue("password", e)} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col rigthAlign ">
                                <Link to="/Recovery" className="links">
                                    <label className='forgetPassword'>Forget Password</label>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="pr-0">
                                <input type="checkbox" id="rememberme" name="rememberme" onChange={e => this.handleChange(e)} />
                                <label htmlFor="rememberme" className='rememberMeText'> Remember me</label>
                            </div>
                        </div>
                        <div className="row submitButtonSection">
                            <div className="col text-center">
                                <button type="submit" className="btn loginButton">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login