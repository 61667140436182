
import { Modal } from "react-bootstrap";

function ParameterModal(props) {
    return (
        <Modal {...props} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Parameter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="create-user-form" onSubmit={props.onFormSubmit}>
                    <div className="form-group d-flex">
                        <label className="form-label">Parameter:</label>
                        <input className="form-control" type="text" id="name" value={props.parameter} onChange={props.onParameterChange} autoFocus required />
                    </div>
                  
                    <div className="form-submit d-flex justify-content-center">
                        <button className="btn-submit" type="submit">Submit</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
export default ParameterModal;