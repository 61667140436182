import { useState } from "react";
import { Modal } from "react-bootstrap";

function UploadLessonModal(props) {


    return (
        <Modal {...props} centered  scrollable>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Lesson
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                    <form className="create-user-form" onSubmit={props.formSubmit}>

                        <div className="form-group justify-content-center">
                            <input name="file" type="file" onChange={(e) => props.upload(e)}  required />
                        </div>
                    
                        <div className="form-submit d-flex justify-content-center">
                            <button className="btn-submit" type="submit">Submit</button>
                        </div>
                    </form>
            </Modal.Body>
        </Modal>
    );
}
export default UploadLessonModal;