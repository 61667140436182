
import { Modal } from "react-bootstrap";

function CreateUserModal(props) {
    return (
        <Modal {...props} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="create-user-form" onSubmit={props.onFormSubmit}>
                    <div className="form-group d-flex">
                        <label className="form-label">Name:</label>
                        <input className="form-control" type="text" id="name" onChange={props.onNameChange} autoFocus required />
                    </div>
                    <div className="form-group d-flex">
                        <label className="form-label">Email:</label>
                        <input className="form-control" type="email" id="email" onChange={props.onEmailChange} required />
                    </div>
                    <div className="form-group d-flex">
                        <label className="form-label">Role:</label>
                        <select name="form-control" onChange={props.onRoleChange}>
                            <option value="">Select a Role</option>
                            <option value="3">Teacher</option>
                            <option value="4">Content Creator</option>
                        </select>
                        {/* <input className="form-control" type="text" id="role" onChange={props.onRoleChange} required /> */}
                    </div>
                    <div className="form-submit d-flex justify-content-center">
                        <button className="btn-submit" type="submit">Submit</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
export default CreateUserModal;