import React from "react";
import Axios from "axios";
import * as Constants from "../../../assets/helpers/constants";
import joinedImg from "../../../assets/images/student-green.png";
import notJoinedImg from "../../../assets/images/student-gray.png";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
// import backIcon from "../../assets/images/backIcon.png";

class PlayerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stdId: null,
            updatedUrl: null,
            studentsList: [],
            individualStudentId: null,
            individualStudentProgress: 0,
            individualStudentName: "",
            individualStudent: false,
            joinStatus: 0,
            hasControl: false,
            selectedStudentId: null,
            playerWidth: 0,
            playerHeight: 0,
            isPortrait: false,
            hasStudentCompleted: false
        };
    }

    componentDidMount() {
        let windowWidth;
        let windowHeight;
        const aspectRatio = 1.777777778;

        window.addEventListener("orientationchange", event => {
            let checkportrait = false
            if (event.target.screen.orientation.angle === 0) {
                checkportrait = true
            } else {
                checkportrait = false
            }
            this.setState({
                isPortrait: checkportrait
            })
        });

        windowWidth = window.innerWidth * 0.85 * 0.95;
        windowHeight = window.innerHeight * 0.85 * 0.95;



        if (windowWidth / windowHeight < aspectRatio) {
            this.setState({
                playerWidth: windowWidth,
                playerHeight: windowWidth / aspectRatio
            })
        } else {
            this.setState({
                playerWidth: windowHeight * aspectRatio,
                playerHeight: windowHeight
            })
        }

        this.getAllStudents();
        this.myInterval = setInterval(() => this.getAllStudents(), 5000);
        this.checkStudentInfo = setInterval(() => this.state.individualStudent ? this.getIndividualStudentMetrics() : null, 5000);

        window.addEventListener('beforeunload', this.reloadHandler)
    }


    getIndividualStudentMetrics = () => {
        if (this.state.individualStudent) {
            //  Get indivudualStudent Metrics
            Axios.get(
                Constants.BASE_URL + `api/getIndividualStudentMetrics/${this.props.classroomid}/${this.state.individualStudentId}`,
            ).then((result) => {
                if (result.data.status) {
                    if (result.data.info.length > 0 && result.data.info[0].Progress === 100) {
                        this.setState({
                            hasStudentCompleted: true
                        })
                    } else {
                        this.setState({
                            hasStudentCompleted: false
                        })
                    }
                } else {
                    this.setState({
                        hasStudentCompleted: false
                    })
                }
            });
        }
    }

    getAllStudents = () => {
        const data = {
            classId: this.props.classroomid,
        };
        Axios.post(
            Constants.BASE_URL + `api/fetchAllStudentsOfClassRoom`,
            data
        ).then((result) => {
            if (result.data.status) {
                this.setState({
                    studentsList: result.data.result,
                });
            } else {
                this.setState({
                    studentsList: [],
                });
                console.log("Error", data);
            }
        });
    };

    reloadHandler(e) {
        e.preventDefault()
        return e.returnValue = "Are you sure you want to exit?";
    }

    onBackButton(e) {
        e.preventDefault();
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
        clearInterval(this.checkStudentInfo);

        window.removeEventListener('beforeunload', this.reloadHandler);
    }

    showIndividualStudent = (studentId, progress, Id, firstName, hasJoined, lastUpdatedTime) => {
        if (lastUpdatedTime > 5) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Student is Offline. Only online students screen can be viewed.",
                showConfirmButton: false,
                timer: 1000
            })
        } else {
            let data = {
                studentId: studentId,
                classId: this.props.classroomid,
                appType: "Teacher",
                lessonId: this.props.lessonId
            };
            Axios.post(
                Constants.BASE_URL + `api/updateStudentTokenToTeacher`,
                data
            ).then((result) => {
                if (result.data.status) {
                    this.setState({
                        updatedUrl: result.data.url + '&width=' + (window.innerWidth * 0.85 * 0.85) + '&height=' + (window.innerHeight * 0.85 * 0.85),
                        individualStudentId: studentId,
                        individualStudentProgress: progress,
                        individualStudent: true,
                        selectedStudentId: Id,
                        individualStudentName: firstName,
                        joinStatus: hasJoined,
                    });
                } else {
                    this.setState({
                        updatedUrl: null,
                    });
                    console.log("Error", data);
                }

            });
        }

    };

    showAllStudents = (studentId) => {
        let data = {
            studentId: studentId,
            classId: this.props.classroomid,
        };
        Axios.post(Constants.BASE_URL + `api/updateStudentXMLToZero`, data).then(
            (result) => {
                if (result.data.status) {
                    this.setState({
                        individualStudentId: null,
                        individualStudent: false,
                        hasControl: false,
                        selectedStudentId: null,
                    });
                } else {
                    alert("Error while updating the XML of student");
                }
            }
        );
    };

    endLesson = () => {
        let data = {
            classId: this.props.classroomid,
        };


        Axios.post(
            Constants.BASE_URL + `api/terminateClassroom`,
            data
        ).then((result) => {
            if (result.data.status) {
                window.parent?.postMessage(
                    {
                        lessonId: this.props.classroomid,
                        info: "LESSON_TERMINATED"
                    }
                    ,
                    Constants.WebSiteUrl
                );
                window.parent?.postMessage(
                    {
                        lessonId: this.props.classroomid,
                        info: "LESSON_TERMINATED"
                    }
                    ,
                    Constants.WebSiteUrl2
                );
                this.props.onHide()
            } else {
                console.log("Error", data);
            }
        });
    };

    takeControl = () => {
        let data = {
            isInteractive: false,
            Id: this.state.selectedStudentId,
        };
        Axios.post(Constants.BASE_URL + `api/setControlOfStudent`, data).then(
            (result) => {
                if (result.data.status) {
                    this.setState({
                        hasControl: true,
                    });
                } else {
                    this.setState({
                        studentsList: [],
                    });
                    console.log("Error", data);
                }
            }
        );
    };

    releaseControl = () => {
        let data = {
            isInteractive: true,
            Id: this.state.selectedStudentId,
        };
        Axios.post(Constants.BASE_URL + `api/setControlOfStudent`, data).then(
            (result) => {
                if (result.data.status) {
                    this.setState({
                        hasControl: false,
                    });
                } else {
                    this.setState({
                        studentsList: [],
                    });
                    console.log("Error", data);
                }
            }
        );
    };

    dateDiff = (timestamp1, timestamp2) => {
        var difference = timestamp1 - timestamp2;
        var secondsDifference = Math.floor(difference / 1000);
        return secondsDifference;
    }


    render() {
        return (
            <Modal {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                fullscreen={true}
            >
                <Modal.Body>

                    <div>
                        {/* <h3><center>All Students thumbnails</center></h3> */}
                        {!this.state.individualStudent && (
                            <div className="container-fluid">

                                <div className="row classroom-title">
                                    <h1>YOU ARE LIVE</h1>
                                    <h5>CLICK ON ANY STUDENT FOR LIVE INTERACTION</h5>
                                </div>
                                <div className="container students-container">
                                    <div className="row classroom-wrapper">

                                        {this.state.studentsList.map((studentDetails, index) => {
                                            var lastModifiedTime = new Date(studentDetails.Last_Modified);
                                            var currenttime = new Date();
                                            var diff = this.dateDiff(currenttime, lastModifiedTime)
                                            let isOnline = false
                                            if (diff > 5) {
                                                //   Is offline show grey
                                                isOnline = false
                                            } else {
                                                //  Is Online show green
                                                isOnline = true
                                            }
                                            return (
                                                <div
                                                    key={index}
                                                    className="col-4 p-md-1 p-sm-1"
                                                    onClick={studentDetails.Progress !== 100 ? () =>
                                                        this.showIndividualStudent(
                                                            studentDetails.Student_Id,
                                                            studentDetails.Progress,
                                                            studentDetails.Id,
                                                            studentDetails.First_Name,
                                                            studentDetails.Has_Joined,
                                                            diff
                                                        ) :
                                                        null
                                                    }
                                                >
                                                    <div className="classroom-students">
                                                        {isOnline && studentDetails.Has_Joined === 1 ? <img className="student-img" src={joinedImg} alt="student Icon" /> : <img className="student-img" src={notJoinedImg} alt="student Icon" />}
                                                        <p className="studentName"> {studentDetails.First_Name} </p>
                                                        {/* <p>  {studentDetails.ClassRoom_Id}</p> */}
                                                        <div className="progress">
                                                            <div
                                                                className="progress-done"
                                                                style={{ width: `${studentDetails.Progress > 100 ? 100 : studentDetails.Progress}%` }}
                                                            ></div>
                                                            <p>{studentDetails.Progress > 100 ? 100 : studentDetails.Progress}%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="end-lesson">
                                        <button className="end-lesson-btn" onClick={this.endLesson}>
                                            END LESSON
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.individualStudent && (
                            <div className="studentDetailsWrapper">
                                <div className='ShowTurnPhone'>
                                    Please view in LandScape Mode
                                </div>
                                {this.state.isPortrait ? (
                                    <>
                                        {/* Portrait Version of selected student screen for tablets*/}
                                        <div className="row studentScreenPortrait">
                                            <button
                                                className="btn backPortrait"
                                                type="button"
                                                onClick={() =>
                                                    this.showAllStudents(this.state.individualStudentId)
                                                }
                                            >
                                                {/* <img className="back-icon" src={backIcon}></img>  */}
                                                &lt; Back
                                            </button>
                                            <div className="col-12 studentScreen">
                                                <iframe className="studentIframePortrait" src={`${this.state.updatedUrl}&width=${this.state.playerWidth * 0.95}&height=${this.state.playerHeight * 0.95}`} width="95%" height="95%" />
                                            </div>
                                            <div className="col-7 m-auto">
                                                <div className="row StudentDetailsWrap">
                                                    <div className="col-6 studentDetailsBox">

                                                        {this.state.studentsList.map((studentDetails, index) => {
                                                            if (
                                                                studentDetails.Student_Id ===
                                                                this.state.individualStudentId &&
                                                                studentDetails.Id === this.state.selectedStudentId
                                                            ) {
                                                                var lastModifiedTime = new Date(studentDetails.Last_Modified);
                                                                var currentTime = new Date();
                                                                var diff = this.dateDiff(currentTime, lastModifiedTime)
                                                                let isOnline = false
                                                                if (diff > 5) {
                                                                    //   Is offline show grey
                                                                    isOnline = false
                                                                } else {
                                                                    //  Is Online show green
                                                                    isOnline = true
                                                                }

                                                                return (
                                                                    <>
                                                                        {this.state.joinStatus === 1 && isOnline ? <img className="individual-student-img" src={joinedImg} /> : <img className="individual-student-img" src={notJoinedImg} />}
                                                                        < p className="studentNamePortrait" > {this.state.individualStudentName}</p>
                                                                        <div className="progress-2 " key={index}>
                                                                            <div
                                                                                className="progress-done"
                                                                                style={{ width: `${studentDetails.Progress > 100 ? 100 : !this.state.hasControl ? studentDetails.Progress : 0}%` }}
                                                                            ></div>
                                                                            <p>{studentDetails.Progress > 100 ? 100 : !this.state.hasControl ? studentDetails.Progress : 0}%</p>
                                                                        </div>
                                                                    </>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                    <div className="col-6 align-self-center text-center">
                                                        {
                                                            this.state.studentsList.map((studentDetails, index) => {

                                                                if (studentDetails.Student_Id === this.state.individualStudentId &&
                                                                    studentDetails.Id === this.state.selectedStudentId) {

                                                                    var lastModifiedTime = new Date(studentDetails.Last_Modified);
                                                                    var currentTime = new Date();
                                                                    var diff = this.dateDiff(currentTime, lastModifiedTime)
                                                                    let isOnline = false
                                                                    if (diff > 5) {
                                                                        //   Is offline disable button
                                                                        isOnline = false
                                                                    } else {
                                                                        //  Is Online enable button
                                                                        isOnline = true
                                                                    }

                                                                    return (
                                                                        this.state.hasControl ? (
                                                                            <button
                                                                                className="btnReleasePortrait"
                                                                                onClick={this.releaseControl}

                                                                            >
                                                                                Release Control
                                                                            </button>
                                                                        ) : (
                                                                            <button className="btnControlPortrait" onClick={this.takeControl} disabled={this.state.hasStudentCompleted || !isOnline ? true : false}>
                                                                                Take Control
                                                                            </button>
                                                                        )
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    // Landscape Version of selected student screen
                                    <div className="row individual-student">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-2  student-details d-flex flex-column align-items-center justify-content-around mr-1">
                                            {/* Control buttons */}
                                            <div className="control">
                                                {
                                                    this.state.studentsList.map((studentDetails, index) => {
                                                        if (
                                                            studentDetails.Student_Id === this.state.individualStudentId &&
                                                            studentDetails.Id === this.state.selectedStudentId
                                                        ) {
                                                            var lastModifiedTime = new Date(studentDetails.Last_Modified);
                                                            var currentTime = new Date();
                                                            var diff = this.dateDiff(currentTime, lastModifiedTime)
                                                            let isOnline = false
                                                            if (diff > 5) {
                                                                //   Is offline disable button
                                                                isOnline = false
                                                            } else {
                                                                //  Is Online enable button
                                                                isOnline = true
                                                            }

                                                            return (
                                                                this.state.hasControl ?
                                                                    (
                                                                        <button className=" btn-release" onClick={this.releaseControl}> Release Control</button>
                                                                    )
                                                                    :
                                                                    (
                                                                        <button className="btn-control" onClick={this.takeControl} disabled={this.state.hasStudentCompleted || !isOnline ? true : false}>
                                                                            Take Control
                                                                        </button>
                                                                    )
                                                            )

                                                        }
                                                    })
                                                }
                                            </div>
                                            <div className="student-box-wrap">
                                                {/* Studnet icon box */}
                                                <div className="student-box ">
                                                    {
                                                        this.state.studentsList.map((studentDetails, index) => {
                                                            if (
                                                                studentDetails.Student_Id === this.state.individualStudentId &&
                                                                studentDetails.Id === this.state.selectedStudentId
                                                            ) {

                                                                var lastModifiedTime = new Date(studentDetails.Last_Modified);
                                                                var currentTime = new Date();
                                                                var diff = this.dateDiff(currentTime, lastModifiedTime)
                                                                let isOnline = false
                                                                if (diff > 5) {
                                                                    //   Is offline show grey
                                                                    isOnline = false
                                                                } else {
                                                                    //  Is Online show green
                                                                    isOnline = true
                                                                }
                                                                return (
                                                                    this.state.joinStatus === 1 && isOnline ? <img className="individual-student-img" src={joinedImg} /> : <img className="individual-student-img" src={notJoinedImg} />
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <div className="individual-student-details align-self-center">
                                                        <p>{this.state.individualStudentName}</p>
                                                    </div>
                                                </div>

                                                {/* Progress bar */}
                                                {this.state.studentsList.map((studentDetails, index) => {
                                                    if (
                                                        studentDetails.Student_Id ===
                                                        this.state.individualStudentId &&
                                                        studentDetails.Id === this.state.selectedStudentId
                                                    ) {
                                                        return (
                                                            <div className="progress-2 " key={index}>
                                                                <div
                                                                    className="progress-done"
                                                                    style={{ width: `${studentDetails.Progress > 100 ? 100 : !this.state.hasControl ? studentDetails.Progress : 0}%` }}
                                                                ></div>
                                                                <p>{studentDetails.Progress > 100 ? 100 : !this.state.hasControl ? studentDetails.Progress : 0}%</p>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>

                                            <button
                                                className="btn back"
                                                type="button"
                                                onClick={() =>
                                                    this.showAllStudents(this.state.individualStudentId)
                                                }
                                            >
                                                {/* <img className="back-icon" src={backIcon}></img>  */}
                                                Back
                                            </button>
                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                                            <div className="student-screen text-center">
                                                <iframe className="student-screen-iframe" src={`${this.state.updatedUrl}&width=${this.state.playerWidth * 0.95}&height=${this.state.playerHeight * 0.95}`} width="95%" height="95%" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                </Modal.Body>
            </Modal>
        )
    }


}

export default PlayerModal;
