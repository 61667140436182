import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import * as Constants from './assets/helpers/constants'
import Contents from './components/Content/Contents'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/MediaQueries.css'
import './assets/css/fonts.css'
import './assets/css/App.css'
import './assets/css/Styles.css'
import ClassRoom from './components/ClassRoom/classroom';
import DemoPlayer from './components/Player/demoPlayer';
import SharedClassroom from './components/ClassRoom/sharedClassRoom';

const App = (props) => {
  return (
    <HashRouter>
      <div className='App'>
        <Switch >
          <Route exact path={Constants.ResetPassword + "/*"} component={() => <Contents pageType="ResetPassword" />} />
          <Route exact path={Constants.Login} component={() => <Contents pageType="Login" />} />
          <Route exact path={Constants.Recovery} component={() => <Contents pageType="Recovery" />} />
          <Route path={Constants.Contents} component={() => <Contents pageType="Normal" />} />
          <Route exact path={Constants.InitialPath} component={() => <Contents pageType="Normal" />} />
          <Route path={Constants.ClassRoom} component={() => <ClassRoom {...props} />} />
          <Route exact path={Constants.PlayerOnly} component={() => <DemoPlayer {...props} />} />
          <Route exact path={Constants.SharedClassroom + "/*"} component={() => <SharedClassroom {...props} />} />
        </Switch>
      </div>
    </HashRouter>
  );
}


export default App;
