import React from 'react';
import Axios from 'axios'
import { withRouter, Route } from 'react-router-dom'
import * as Constants from '../../assets/helpers/constants'
import Login from '../Login/login'
import Recovery from '../Recovery/recovery'
import ResetPassword from '../ResetPassword/resetPassword'
import LeftPanel from '../LeftPanel/LeftPanel';
import ProductHeadUsers from '../ProductHead/users/users';
import Swal from 'sweetalert2'
import Parameters from '../ProductHead/parameters/parameters';
import Analytics from '../ProductHead/analytics/analytics';
import AssignTask from '../ProductHead/assignTask/assignTask';
import PreviewLesson from '../ProductHead/previewLesson/previewLesson';
import Player from '../Player/player';
import Backlog from '../ContentCreator/backlogs/backlog';
import Status from '../ContentCreator/status/status';
import ExploreLessons from '../Teacher/exploreLessons/exploreLessons';
import AdminUsers from '../Admin/users/users';
import AdminSettings from '../Admin/settings/settings';
import Profile from '../Profile/profile'

var myTimer;
var reminderTimerValue;
var checkCred;

class Contents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            showBackDrop: (props.pageType === "Login" || props.pageType === "Recovery" || props.pageType === "Registration" || props.pageType === "ResetPassword") ? false : false,
            experienceList: [],
            filteredExperienceList: [],
            UserInfo: {},
            UserDetailsInfo: [],
            companyName: null,
            companyId: null,
            files: [],
            foldersFiles: [],
            filteredMMFiles: [],
            filteredMMFolders: [],
            location: null,
            selectedLicenses: [],
            selectedDevices: [],
            expName: null,
            folderName: null,
            activeLink: null,
        }
    }

    componentDidMount() {
        if (this.props.pageType !== "Login" && this.props.pageType !== "Recovery" && this.props.pageType !== "ResetPassword") {
            if (localStorage.getItem("userDetails") === null) {
                this.props.history.push('/Login');
            } else {
                let userDetails = JSON.parse(localStorage.getItem("userDetails"))

                if (this.props.location.pathname.includes("/Contents/") && this.props.location.pathname.length > 15) {
                    this.props.history.push(this.props.location.pathname);
                }
                this.setState({
                    UserInfo: userDetails[0],
                    UserDetailsInfo: userDetails
                }, () => {
                    if (this.props.location.pathname === "/Contents" || (this.props.location.pathname === "/Contents/" && this.props.location.pathname.length === 10)) {
                        switch (userDetails[0].Role) {
                            case Constants.Admin:
                                this.props.history.push(Constants.AdminDashboard)
                                break
                            case Constants.ProductHead:
                                this.props.history.push(Constants.ProductHeadUsers)
                                break
                            case Constants.ContentCreator:
                                this.props.history.push(Constants.BacklogDashboard)
                                break
                            case Constants.Teacher:
                                this.props.history.push(Constants.TeacherDashboard)
                                break
                        }

                    }
                })
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(myTimer);
        clearTimeout(reminderTimerValue);
    }

    inactivityTime = () => {
        var logoutTimer = 1800000
        var remainderTimer = 1500000
        var credentialsCheckTimer = 15000
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;
        document.onkeyup = resetTimer;

        function resetTimer() {
            if (localStorage.getItem("userDetails") !== null) {
                clearTimeout(myTimer);
                clearTimeout(reminderTimerValue);
                myTimer = setTimeout(logout, logoutTimer);// 20 sec it will log out the session 
                reminderTimerValue = setTimeout(reminderTimer, remainderTimer); // 15s it will trigger popup
                checkCred = setTimeout(checkUserCred, credentialsCheckTimer);
            }
        }

        function checkUserCred() {
            if (localStorage.getItem("userDetails") === null) {
                if (!(window.location.href.includes(Constants.Login)) && !(window.location.href.includes(Constants.Recovery)) && !(window.location.href.includes(Constants.ResetPassword)) && !(window.location.href.includes(Constants.PartnerRegistration))) {
                    window.location.href = window.location.origin
                }
            }
        }

        function reminderTimer() {
            Swal.fire({
                title: 'User Session will end in 5 minutes if no activity is performed ',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    resetTimer()
                }
            })
        }

        function logout() {
            clearTimeout(myTimer);
            clearTimeout(reminderTimerValue);
            clearTimeout(checkCred);
            localStorage.removeItem('userDetails')
            localStorage.removeItem('rememberme')
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Logging Out User. Please login again !!',
                showConfirmButton: false,
                timer: 2000
            }).then((result) => {
                window.location.href = window.location.origin
            })
        }
    };

    logout = () => {
        localStorage.removeItem('userDetails')
        localStorage.removeItem('rememberme')
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Logging Out User. Please login again !!',
            timer: 2000
        })
        this.props.history.push(Constants.Login)
    }

    changeLink = (value) => {
        this.setState({
            activeLink: value
        })
    }

    getExperiences = (company = this.state.companyName + "_" + this.state.companyId) => {
        this.setState({
            isLoading: true,
            experienceList: [],
            filteredExperienceList: []
        }, () => {
            var x = company.split("_")
            Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getExperiencs/${x[1]}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        experienceList: result.data.info,
                        filteredExperienceList: result.data.info,
                        companyName: x[0],
                        companyId: x[1],
                        isLoading: false
                    })
                } else {
                    this.setState({
                        experienceList: [],
                        filteredExperienceList: [],
                        companyName: x[0],
                        companyId: x[1],
                        isLoading: false
                    })
                }
            })
        })
    }

    searchExperiences = (value) => {
        let experiences = this.state.experienceList
        let newArray = []
        newArray = experiences.filter(function (item) {
            return item.ExperienceName.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({
            filteredExperienceList: newArray
        }, () => {
            this.props.history.push(Constants.ExperienceDashboard)
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="container m-0 p-0 commonSection" >
                    <div className="row">
                        <div className="col">
                            <Route exact path={Constants.Login} component={() => <Login {...this.props} />} />
                            <Route exact path={Constants.Recovery} component={() => <Recovery {...this.props} />} />
                            <Route exact path={Constants.ResetPassword + "/:pageType/:uniqueId"} component={() => <ResetPassword {...this.props} />} />
                        </div>
                    </div>
                </div>


                <div className="container-fluid m-0 p-0 dashboardSection" >
                    {this.state.UserInfo.RoleId !== undefined ?
                        <div className='row p-0 m-0'>
                            <div className='col-2 p-0'>
                                <LeftPanel activeLink={this.state.activeLink} changeLink={this.changeLink} roleId={this.state.UserInfo.RoleId} />
                            </div>
                            <div className='col-10'>
                                <Route exact path={Constants.ProductHeadUsers} component={() => <ProductHeadUsers logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.ParameterDashboard} component={() => <Parameters logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.AnaliticsDashboard} component={() => <Analytics logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.AssignTaskScreen} component={() => <AssignTask logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.PreviewLessonsDashboard} component={() => <PreviewLesson logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.Player + "/*"} component={() => <Player experienceId={1} logout={this.logout} userId={this.state.UserInfo.UserID} {...this.props} />} />
                                <Route exact path={Constants.BacklogDashboard} component={() => <Backlog logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.StatusDashboard} component={() => <Status logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.TeacherDashboard} component={() => <ExploreLessons logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.AdminDashboard} component={() => <AdminUsers logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.Settings} component={() => <AdminSettings logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} {...this.props} />} />
                                <Route exact path={Constants.ProfileDashboard} component={() => <Profile logout={this.logout} userId={this.state.UserInfo.UserID} name={this.state.UserInfo.Name} email={this.state.UserInfo.Email} role={this.state.UserInfo.Role} company={this.state.UserInfo.CompanyName} {...this.props} />} />
                            </div>
                        </div>
                        : null
                    }
                </div>

                {/* <div className="row userDashboard">
                        <div className="col-md-9 p-0">
                            <LeftSidePanel checkPath={this.checkPath} activeLink={this.state.activeLink} UserInfo={this.state.UserInfo} closeFunc={this.closeLeftSidePanels} {...this.props} />
                            <div className="middleContent pr-4">
                                <Header UserInfo={this.state.UserInfo} {...this.props} />
                                <Route path={Constants.ExperienceDashboard} render={() => <ExperienceDashboard UserInfo={this.state.UserInfo} searchExperiences={this.searchExperiences} filteredExperienceList={this.state.filteredExperienceList} experienceList={this.state.experienceList} getExperiences={this.getExperiences} {...this.props} />} />
                                <Route path={Constants.SettingsDashboard} render={() => <SettingsDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                            </div>
                        </div>
                        <div className="col-md-3 wrapper p-0">
                            <RightSidePanel inactivityTime={this.inactivityTime} UserDetailsInfo={this.state.UserDetailsInfo} incrementViews={this.incrementViews} getProjectDetails={this.getProjectDetails} getLicenses={this.getLicenses} licenceInfo={this.state.licenceInfo} checkPath={this.checkPath} UserInfo={this.state.UserInfo} getDevices={this.getDevices} getFileDetails={() => this.getFileDetails()} companyName={this.state.companyName} experienceList={this.state.experienceList} filteredExperienceList={this.state.filteredExperienceList} getExperiences={this.getExperiences} getFileManagerFolderDetails={this.getFileManagerFolderDetails} {...this.props} />
                        </div>
                    </div> */}
            </React.Fragment >
        )
    }
}

export default withRouter(Contents)