import { useState } from "react";
import Swal from "sweetalert2";
import Header from "../Header";
import LoaderComp from "../Loader";
import Axios from 'axios';
import * as Constants from '../../assets/helpers/constants'

function Profile(props) {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [reNewPassword, setReNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const changeValue = (value, func) => {
        func(value)
    }

    const resetPassword = (e) => {
        e.preventDefault()
        setIsLoading(true)

        if (oldPassword.trim() === '' || newPassword.trim() === '' || reNewPassword.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory !!",
                showConfirmButton: false,
                timer: 1500
            })
            setIsLoading(false)
            return
        }

        if (newPassword !== reNewPassword) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "New Password and Re-Enter Password should be same !!",
                showConfirmButton: false,
                timer: 2000
            })
            setIsLoading(false)
            return
        }

        const userEmail = props.email
        console.log('email', userEmail)

        let data = {
            oldPassword,
            newPassword,
            userEmail
        }

        Axios.post(Constants.BASE_URL + `api/ChangePassword`, data).then((result) => {

            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Updated Password Successfully !!',
                    showConfirmButton: false,
                    timer: 1000
                })
                setOldPassword('')
                setNewPassword('')
                setReNewPassword('')
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: result.data.info,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }

    return (
        <>
            {isLoading ? (<LoaderComp isLoading={isLoading} />) : null}
            <div className="main-content analyticsDashboard">
                <Header title="Profile" {...props} />
                <div className="row data-container-wrapper">
                    <div className="col-12 profile-section">
                        <div className="row text-center mt-4 ">
                            <div className="col-4">
                                <p>Name</p>
                            </div>
                            <div className="col-4">
                                <p>{props.name}</p>
                            </div>
                        </div>
                        <div className="row text-center mt-4 ">
                            <div className="col-4">
                                <p>Email</p>
                            </div>
                            <div className="col-4">
                                <p>{props.email}</p>
                            </div>
                        </div>
                        <div className="row text-center mt-4 ">
                            <div className="col-4">
                                <p>Role</p>
                            </div>
                            <div className="col-4">
                                <p>{props.role}</p>
                            </div>
                        </div>
                        <div className="row text-center mt-4 ">
                            <div className="col-4">
                                <p>Company</p>
                            </div>
                            <div className="col-4">
                                <p>{props.company}</p>
                            </div>
                        </div>
                        <div className="row text-center mt-4 ">
                            <div className="col-4">
                                <p>Reset Password</p>
                            </div>
                            <div className="col-4">
                                <form className="reset-password-form" onSubmit={resetPassword}>
                                    <input className="reset-password" type="password" placeholder="Current Password" value={oldPassword} onChange={(e) => changeValue(e.target.value, setOldPassword)} required />
                                    <input className="reset-password" type="password" placeholder="New Password" value={newPassword} onChange={(e) => changeValue(e.target.value, setNewPassword)} required />
                                    <input className="reset-password" type="password" placeholder="Re-Enter Password" value={reNewPassword} onChange={(e) => changeValue(e.target.value, setReNewPassword)} required />
                                    <button type="submit" className="btn password-submit-btn">Submit</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Profile;