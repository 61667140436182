import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import exportFromJSON from 'export-from-json'

class Analytics extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: null,
            toDate: null
        }
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    getData = () => {
        let data = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        Axios.post(Constants.BASE_URL + `api/downloadAnalytics`, data).then((result) => {
            if (result.data.status) {
                if (result.data.info.length > 0) {
                    const data = result.data.info
                    const fileName = 'download'
                    const exportType = exportFromJSON.types.csv
                    exportFromJSON({ data, fileName, exportType })
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Download has started. Please wait!',
                        showConfirmButton: false,
                        timer: 2500
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'No data available from the selected dates. Try again with different dates!',
                        showConfirmButton: false,
                        timer: 2500
                    })
                }
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error while fetching data from the selected dates. Try again!',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        })
    }

    render() {
        return (
            <div className="main-content analyticsDashboard">
                <Header title="Analytics Dashboard" {...this.props} />
                <div className="row data-container-wrapper">
                    <div className="col-6 text-center dateFields">
                        <label htmlFor='fromdate' className='inputLabels'>From </label><br />
                        <input type="date" id='fromdate' className="inputFields noOutline noBorder " max={new Date().toISOString().split('T')[0]} onChange={(e) => this.changeValue("fromDate", e.target.value)} required />
                    </div>
                    <div className="col-6 text-center dateFields">
                        <label htmlFor='todate' className='inputLabels'>To </label><br />
                        <input type="date" id='todate' className="inputFields noOutline noBorder " max={new Date().toISOString().split('T')[0]} onChange={(e) => this.changeValue("toDate", e.target.value)} required />
                    </div>
                    <div className="col-12 text-center">
                        <button type="button" className="btn downloadBtn" onClick={this.getData}>Download</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Analytics;