import { useEffect, useState } from "react";
import LoaderComp from "../Loader";
import Swal from "sweetalert2";
import joined from "../../assets/images/student-green.png";
import notJoined from "../../assets/images/student-gray.png";
import lesson1 from "../../assets/images/lesson1.jpg"
import lesson2 from "../../assets/images/lesson2.jpg"
import lesson3 from "../../assets/images/lesson3.jpg"
import lesson4 from "../../assets/images/lesson4.jpg"
import lesson5 from "../../assets/images/lesson5.jpg"
import lesson6 from "../../assets/images/lesson6.jpg"
import lesson7 from "../../assets/images/lesson7.jpg"
import lesson8 from "../../assets/images/lesson8.jpg"
import lesson9 from "../../assets/images/lesson9.jpg"
import lesson10 from "../../assets/images/lesson10.jpg"


function AddStudent(props) {
    const [studentName, setStudentName] = useState("");
    const [studentEmail, setStudentEmail] = useState("");
    const [isIntro, setIsIntro] = useState(false);
    const [mailErr, setMailErr] = useState('');
    const [lessonId, setLessonId] = useState();
    const studentCount = [0, 1, 2, 3, 4, 5, 6, 7, 8];

    // let url = `https://lessons.baethoedu.com?Id=${props.lessonID}`;
    let url = `http://intbae.com/integrations/flutter/player?Id=${props.lessonID}`;

    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const onEnteredName = (e) => {
        setStudentName(e.target.value);
    };

    const onEnteredEmail = (e) => {
        setStudentEmail(e.target.value);
    };

    const submitHandler = (e) => {
        e.preventDefault()
        if (studentName.trim().length === 0 || studentEmail.trim().length === 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'All fields are mandatory.',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        if (regEx.test(studentEmail) === false) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please enter a valid email!',
                showConfirmButton: false,
                timer: 1000
            })
            // setMailErr("Invalid Email Address")
            return
        }
        // setMailErr("")


        props.onAddStudent(studentEmail, studentName)
        setStudentName('');
        setStudentEmail('');
    };

    const getJoinStatus = () => {
        props.joinStatus();
    };

    useEffect(() => {
        setLessonId(props.lessonID)
        getJoinStatus();
        const interval = setInterval(() => {
            getJoinStatus();
        }, 10000);

        return () => {
            clearInterval(interval)
            window.removeEventListener('beforeunload', reloadHandler)
        };
    }, []);

    const showIntro = (value) => {
        setIsIntro(value);
    };

    const preventReload = (e) => {
        window.addEventListener('beforeunload', reloadHandler)
    }

    const reloadHandler = (e) => {
        e.preventDefault()
        return e.returnValue = "Are you sure you want to exit?";
    }

    return (
        <div className="container-fluid add-student">
            {props.loading ? <LoaderComp isLoading={props.loading} /> : null}
            <div className="row">
                <div className="add-student-title col-12">
                    <h1>LET’S CREATE A CLASSROOM</h1>
                </div>
            </div>
            {/* Add student landscape version */}
            <div className="row add-student-inner ">
                <div className="col-12">
                    <div className="row  add-student-top">

                        <div className="col-md-5 col-sm-6 col-7 offset-md-3 offset-sm-2 offset-1" >
                            <div className="lesson-img">
                                {props.lessonID == 1 && <img src={lesson1} alt="English" />}
                                {props.lessonID == 2 && <img src={lesson2} alt="Logic" />}
                                {props.lessonID == 3 && <img src={lesson3} alt="Maths" />}
                                {props.lessonID == 4 && <img src={lesson4} alt="Science" />}
                                {props.lessonID == 5 && <img src={lesson5} alt="Physics" />}
                                {props.lessonID == 6 && <img src={lesson6} alt="Typing" />}
                                {props.lessonID == 7 && <img src={lesson7} alt="Integers" />}
                                {props.lessonID == 8 && <img src={lesson8} alt="NumberHunt" />}
                                {props.lessonID == 9 && <img src={lesson9} alt="Geography" />}
                                {props.lessonID == 10 && <img src={lesson10} alt="Speed" />}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-3 p-0">
                            <div className="add-student-main align-self-center">
                                <div className="row add-student-section p-0">
                                    <div className="col-12 p-0">
                                        <p className='add-title'>Enter Student Details</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-10 add-student-wrap ">
                                        <form className='add-student-form ' onSubmit={submitHandler} onFocus={(e) => { preventReload(e) }}>

                                            <input name="fname" type="text" placeholder="Name" minLength="3" maxLength="50" value={studentName} onChange={onEnteredName} required />
                                            <input name="email" type="email" placeholder="Email" minLength="8" maxLength="50" value={studentEmail} onChange={onEnteredEmail} required />

                                            <div className="row button-wrap">
                                                <div className="col-6 button-inner p-0">
                                                    <button className='add-student-btn' type="submit">Add</button>
                                                </div>
                                                <div className="col-6 button-inner p-0">
                                                    <button type="button" className="start-lesson-btn" onClick={props.playPlayer}>Start Lesson</button>
                                                </div>
                                            </div>
                                        </form>
                                        {/* {
                                            mailErr ? (<span className='student-mail-error'>{mailErr}</span>) :
                                                props.errorMsg ? (<span className='student-mail-error'>{props.errorMsg}</span>) :
                                                    null
                                        } */}

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="row student-list">
                        <div className="col-12 student-grid">
                            {
                                studentCount.map((element, index) => {
                                    let firstName = ""
                                    let imgSrc = notJoined
                                    if (props.studentList.length > 0 && props.studentList[element]) {
                                        firstName = props.studentList[element].First_Name
                                        if (props.studentList[element].Has_Joined) {
                                            imgSrc = joined
                                        }
                                    }
                                    return (
                                        <div className="student-single" key={index}>
                                            <div><img src={imgSrc} /><div className="std-id"><p>{firstName}</p></div></div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {
                            // studentCount.map((element) => {
                            //     let firstName = ""
                            //     let imgSrc = notJoined
                            //     if (props.studentList.length > 0 && props.studentList[element]) {
                            //         firstName = props.studentList[element].First_Name
                            //         if (props.studentList[element].Has_Joined) {
                            //             imgSrc = joined
                            //         }
                            //     }
                            //     return (
                            //         <div className="col-4 student-grid-mobile center-block">
                            //             <img className="img-responsive" src={imgSrc} />
                            //             <div className="std-id-mobile"><p>{firstName}</p></div>
                            //         </div>
                            //     )
                            // })
                        }

                    </div>
                </div>
            </div>
            {/* Add student portrait version */}
            <div className="row addStudentPortrait">
                <div className="col-12 addStdBg">
                    <div className="row">
                        <div className="col-12">
                            {props.lessonID == 1 && <img className="addStudentLessonImg" src={lesson1} alt="English" />}
                            {props.lessonID == 2 && <img className="addStudentLessonImg" src={lesson2} alt="Logic" />}
                            {props.lessonID == 3 && <img className="addStudentLessonImg" src={lesson3} alt="Maths" />}
                            {props.lessonID == 4 && <img className="addStudentLessonImg" src={lesson4} alt="Science" />}
                            {props.lessonID == 5 && <img className="addStudentLessonImg" src={lesson5} alt="Physics" />}
                            {props.lessonID == 6 && <img className="addStudentLessonImg" src={lesson6} alt="Typing" />}
                            {props.lessonID == 7 && <img className="addStudentLessonImg" src={lesson7} alt="Integers" />}
                            {props.lessonID == 8 && <img className="addStudentLessonImg" src={lesson8} alt="NumberHunt" />}
                            {props.lessonID == 9 && <img className="addStudentLessonImg" src={lesson9} alt="Geography" />}
                            {props.lessonID == 10 && <img className="addStudentLessonImg" src={lesson10} alt="Speed" />}
                        </div>
                    </div>
                    <div className="row studentsGridPortrait">
                        {
                            studentCount.map((element, index) => {
                                let firstName = ""
                                let imgSrc = notJoined
                                if (props.studentList.length > 0 && props.studentList[element]) {
                                    firstName = props.studentList[element].First_Name
                                    if (props.studentList[element].Has_Joined) {
                                        imgSrc = joined
                                    }
                                }
                                return (
                                    <div key={index} className="col-4 studentPortrait center-block text-center">
                                        <img className="img-responsive" src={imgSrc} />
                                        <div className="std-id-mobile"><p>{firstName}</p></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className=" col-12 text-center mx-auto">
                    <div className="row formPortrait">
                        <div className="col-12">
                            <p className="formTitle">Enter Student Details</p>
                            <form className='addStudentform ' onSubmit={submitHandler} onFocus={(e) => { preventReload(e) }}>

                                <input name="fname" type="text" placeholder="Name" minLength="3" maxLength="50" value={studentName} onChange={onEnteredName} required />
                                <input name="email" type="email" placeholder="Email" minLength="8" maxLength="50" value={studentEmail} onChange={onEnteredEmail} required />

                                <div className="row buttonWrapPortrait">
                                    <div className="col-6 buttonInnerPortrait p-0">
                                        <button className='addStudentBtn' type="submit">Add</button>
                                    </div>
                                    <div className="col-6 buttonInnerPortrait p-0">
                                        <button type="button" className="startLessonBtn" onClick={props.playPlayer}>Start Lesson</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddStudent;
