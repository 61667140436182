import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import searchIcon from '../../../assets/images/searchIcon.png'
import PreViewLessonModal from "./previewLessonModal";
import PlayerModal from "./playerModal";



function ExploreLessons(props) {

    const [lessons, setLessons] = useState('');
    const [classroomLink, setClassroomLink] = useState(false);
    const [lessonDetails, setLessonDetails] = useState([]);
    const [dataFromApi, setDataFromApi] = useState('');
    const [isVerified, setIsVerfied] = useState(false);
    const [baseURL, setBaseURL] = useState('')
    const [classroomId, setClassroomId] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [lessonId, setLessonId] = useState('')
    const [classroomPlayerPopup, setClassroomPlayerPopup] = useState(false)



    const getLessons = () => {
        Axios.get(Constants.BASE_URL + `api/getAllApprovedLessons`).then((result) => {

            if (result.data.status) {
                setLessons(result.data.info)
                setDataFromApi(result.data.info)
            } else {
                setLessons([])
                setDataFromApi([])
            }
        })
    }

    const getCompanyDetails = () => {
        const companyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId;
        Axios.get(Constants.BASE_URL + `api/getCompanyDetails/${companyId}`).then((result) => {
            if (result.data.status) {

                if (result.data.info[0].Base_URL != undefined && result.data.info[0].Base_URL !== "") {
                    if (result.data.info[0].Base_URL.substr(-1) != '/') result.data.info[0].Base_URL += '/';
                    setBaseURL(result.data.info[0].Base_URL)
                }
                else {
                    setBaseURL('')
                }

            } else {
                setBaseURL('');
            }
        })
    }

    useEffect(() => {
        getLessons()
        getCompanyDetails()
    }, [])


    //search Lesson
    const filterData = (e) => {
        if (e.target.value === '') {
            setLessons(dataFromApi)
        } else {
            const filteredResult = dataFromApi.filter((item) => {

                return item.Lesson.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Subject.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Class.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Chapter.toLowerCase().includes(e.target.value.toLowerCase());

            });
            setLessons(filteredResult);
        }
    }

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Class",
            center: true,
            selector: (row) => row.Class,
            grow: 1
        },
        {
            name: "Subject",
            center: true,
            selector: (row) => row.Subject,
            grow: 1
        },
        {
            name: "Chapter",
            center: true,
            selector: (row) => row.Chapter,
            grow: 1
        },
        {
            name: "Lesson",
            center: true,
            selector: (row) => row.Lesson,
            grow: 1
        },
        {
            name: "Preview",
            center: true,
            cell: row => {
                return <button className="btn-action preview" type="button" onClick={() => togglePreviewPopup(row.Id)}>Play</button>
            }
        },
        {
            name: "Actions",
            center: true,
            grow: 2,
            cell: row => {
                return (
                    <div className="actions">
                        <button className="btn-classroom w-200" type="button" onClick={() => showClassroomLink([row.Class, row.Subject, row.Chapter, row.Lesson])}>Start Classroom</button>
                    </div>
                )
            }
        }
    ]

    const togglePreviewPopup = (rowId) => {
        setShowModal(!showModal);
        setLessonId(rowId);
    }

    const verifyClassroomId = () => {
        const classroomId = (Math.random() + 1).toString(36).substring(7)
        let data = {
            classId: classroomId
        }
        Axios.post(Constants.BASE_URL + 'api/verfiyClassroomId', data).then((result) => {
            if (result.data.status) {
                setIsVerfied(true)
                setClassroomId(classroomId)
            } else {
                verifyClassroomId()
            }
        })
    }

    const showClassroomLink = (lessons) => {
        //window.open(`${Constants.WebSiteUrl}oneToManyClassRoom?link=${Constants.ApplicationUrl}ClassRoom?lesson=${lessonId}`, '_blank');
        setLessonDetails(lessons)
        setClassroomLink(true)
        verifyClassroomId()
    }

    const openPlayer = (classId, lessonId) => {

        let userDetails = JSON.parse(localStorage.getItem("userDetails"))[0]
        let data = {
            studentId: userDetails.Name,
            classId: classId,
            type: "Teacher",
            lessonId: lessonId,
            firstName: userDetails.Name,
            email: userDetails.Email,
            source: "Assisted",
        }
        Axios.post(Constants.BASE_URL + `api/createClassRoomFromTeacher`, data).then((result) => {
            if (result.data.status) {
                this.setState({
                    url: result.data.url,
                    finalUrl: `${Constants.WebSiteUrl}oneToManyClassRoom?link=${Constants.ApplicationUrl}ClassRoom?url=${result.data.url}`,
                    collectedDetails: true,
                    isDisabled: false
                }, () => {
                    try {
                        console.log("Add teacher Trying")
                        window.localStorage['collectedDetails'] = true
                    } catch (e) {
                        console.log("Add teacher Catching")
                        console.log(e)
                    }
                })
            } else {
                console.log("Error", result)
                this.setState({
                    isDisabled: false
                })
            }
        })
        setClassroomPlayerPopup(true)
    }

    const closePlayer = () => {
        setClassroomPlayerPopup(false)
        props.history.push(Constants.TeacherDashboard)
    }
    const copyClassUrl = (classroomLink) => {
        navigator.clipboard?.writeText && navigator.clipboard.writeText(classroomLink)
    }
    return (
        <React.Fragment>
            {classroomLink !== true ?
                <div className="main-content">
                    <PreViewLessonModal show={showModal} onHide={togglePreviewPopup} lessonID={lessonId} />
                    <Header title="ExploreLesson" {...props} />
                    <div className="row">
                        <div className="col-12 filtersSection">
                            <div className="row">
                                <div className="col-7">
                                </div>
                                <div className="col-5 rigthAlign">
                                    <label className="search-bar preview-lesson">
                                        <input className="search" placeholder="Search" onChange={filterData} />
                                        <span></span>
                                        <img className="searchIcon" src={searchIcon} alt="Search" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row data-container-wrapper">
                        <DataTable
                            columns={columns}
                            data={lessons}
                            pagination
                            fixedHeader
                            customStyles={customStyles}
                            persistTableHead
                            noDataComponent
                        />
                    </div>

                </div>
                :
                <div className="main-content">
                    <Header title="Share Link" {...props} />

                    <div className="row data-container-wrapper">
                        <div className="col-12 ">
                            <div className="row back-btn">
                                <Link to="ExploreLessons" className=""><button className="btn">Back</button></Link>
                            </div>
                            <table className="lesson-table">
                                <thead >
                                    <th className="table-head">Class</th>
                                    <th className="table-head">Subject</th>
                                    <th className="table-head">Chapter</th>
                                    <th className="table-head">Lesson</th>
                                    <th className="table-head">Link</th>
                                </thead>
                                <tbody>
                                    <td className="lesson-detail">{lessonDetails[0]}</td>
                                    <td className="lesson-detail">{lessonDetails[1]}</td>
                                    <td className="lesson-detail">{lessonDetails[2]}</td>
                                    <td className="lesson-detail">{lessonDetails[3]}</td>
                                    <td className="lesson-detail">

                                        <input type="text" className="copyClassUrl" value={`${baseURL}#/classroom?classroomid=${classroomId}&lessonpath=${lessonDetails[3]}`} />
                                        <button className="btn btn-success mt-2" title={`${baseURL}#/classroom?classroomid=${classroomId}&lessonpath=${lessonDetails[3]}`}
                                            onClick={() => copyClassUrl(`${baseURL}#/classroom?classroomid=${classroomId}&lessonpath=${lessonDetails[3]}`)}>Copy Class Url
                                        </button></td>
                                </tbody>
                            </table>

                            <div className="classroom-link">
                                <button className="btn btn-success mt-5" type="button" onClick={() => openPlayer(classroomId, lessonDetails[3])}>Start ClassRoom</button>
                                {classroomPlayerPopup && <PlayerModal classroomid={classroomId} show={true} onHide={closePlayer} />}
                            </div>
                        </div>

                    </div>
                </div>
            }
        </React.Fragment>
    )
}
export default ExploreLessons;