import { useEffect, useState } from "react";
// import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from "../../Loader";
import EditBaseURLModal from "./editBaseURLModal";
import Swal from "sweetalert2";

function AdminSettings(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [inputURL, setInputURL] = useState('');
    const [showModal, setShowEditModal] = useState(false);
    const [baseURL, setBaseURL] = useState('');
    const [file, setFile] = useState('');
    const [version, setVersion] = useState('');


    const enteredURL = (e) => {
        setInputURL(e.target.value)
    }
    const toggleModal = () => {
        setShowEditModal(!showModal)
        setInputURL(baseURL);
    }

    const getCompanyDetails = () => {
        const companyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId;
        Axios.get(Constants.BASE_URL + `api/getCompanyDetails/${companyId}`).then((result) => {
            if (result.data.status) {
                setBaseURL(result.data.info[0].Base_URL)
            } else {
                setBaseURL('');
            }
        })
    }

    useEffect(() => {
        getCompanyDetails()
    }, [])

    //Edit base url
    const onBaseURLEdit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID;

        let data = {
            baseURL: inputURL,
            userId: userID,
        }

        Axios.post(Constants.BASE_URL + `api/CreateBaseUrl`, data).then((result) => {

            setIsLoading(false)
            setShowEditModal(false)
            if (result.data.status) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Base URL Edited Successfuly",
                    showConfirmButton: false,
                    timer: 1000
                })
                getCompanyDetails()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to edit Base URL.Please try again",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    const uploadFile = () => {

        if (!file) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select a file to upload.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (!version || version.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Version cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        const extension = file.name.split(".").pop();
        if (extension !== 'exe') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select .exe file only.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        setIsLoading(true)

        const fromData = new FormData();
        fromData.append("fileUpload", file);
        fromData.append("version", version);

        Axios.post(Constants.BASE_URL + "api/uploadComposer", fromData).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "File Uploaded succesfully",
                    showConfirmButton: false,
                    timer: 2000
                })


            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Error while Uploading file",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });

    }


    return (
        <div className="main-content">
            <Header title="Settings" {...props} />
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
            <EditBaseURLModal show={showModal} onHide={toggleModal} onFormSubmit={onBaseURLEdit} editURL={inputURL} onBaseURLChange={enteredURL} />
            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        {/* <div className="col-7">
                            <label className="sort-btn" onClick={(e) => { handleSort('FirstName') }}>
                                <span className="filters">Name</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={(e) => { handleSort('Email') }}>
                                <span className="filters">Email</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={(e) => { handleSort('Created_On') }}>
                                <span className="filters">Created On </span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='sort' />
                            </label>
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="create-user-btn" onClick={createUser}>
                                <span className="add-user" >+ User</span>
                            </label>
                            <label className="search-bar">
                                <input className="search" placeholder="Search" onChange={filterData} />
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <div className="col-12">
                    <div className="row text-center pt-3 align-items-center">
                        <div className="col-4">
                            <label className="setting-label">Base URL</label>
                        </div>
                        <div className="col-4">
                            <label className="base-url">{baseURL}</label>
                        </div>
                        <div className="col-4">
                            <button className="btn-action" type="button" onClick={toggleModal}>Edit</button>
                        </div>
                    </div>
                    <div className="row pt-3 align-items-center">
                        <div className="col-4 text-center">
                            <label className="setting-label">Upload Composer</label>
                        </div>
                        <div className="col-4 text-center">
                            <input className="uploadInput" type="text" placeholder="Version" value={version} onChange={(e) => setVersion(e.target.value)} required />
                        </div>
                        <div className="col-4 mt-4">
                            <div className="row">
                                <div className="col-6 text-center">
                                    <input className="choose-file" id="file" name="file" type="file" onChange={(e) => setFile(e.target.files[0])} required />
                                </div>
                                <div className="col-6 ">
                                    <button className="btn-action" type="button" onClick={uploadFile}>Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdminSettings;