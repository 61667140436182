import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import CreateLessonModal from "./createLessonModal";
import LoaderComp from "../../Loader";
import ascImg from '../../../assets/images/sort-asc.png'
import descImg from '../../../assets/images/sort-desc.png'
import searchIcon from '../../../assets/images/searchIcon.png';

function AssignTask(props) {
    const [lessons,setLessons] = useState([])
    const [contentCreators, setContentCreators] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [newClass, setNewClass] = useState('')
    const [newSubject, setNewSubject] = useState('')
    const [newLesson, setNewLesson] = useState('')
    const [newChapter, setNewChapter] = useState('')
    const [assigned, setAssigned] = useState('');
    const [dataFromApi,setDataFromApi] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [isLoading, setIsLoading] = useState(false);
    

    const enteredClass = (e) =>{
        setNewClass(e.target.value)
    }
    const enteredSubject = (e) =>{
        setNewSubject(e.target.value)
    }
    const enteredLesson = (e) =>{
        setNewLesson(e.target.value)
    }
    const enteredChapter = (e) =>{
        setNewChapter(e.target.value)
    }
    const assignedTo = (e) =>{
        setAssigned(e.target.value)
    }


    //search Lesson
    const filterData = (e) => {
    if(e.target.value == '')
    {
        setLessons(dataFromApi)
        } else{
        const filteredResult = dataFromApi.filter((item) => {
                
            return item.Lesson.toLowerCase().includes(e.target.value.toLowerCase()) || 
            item.Subject.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.Class.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.Chapter.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.Status.toLowerCase().includes(e.target.value.toLowerCase());
                
            });
            setLessons(filteredResult);
        }
    }


    //sort Lessons
    const handleSort = (selector) => {
    if(sortOrder === 'asc')
    {
        const sorted = [...lessons].sort((a,b) => {
            if(a[selector].toLowerCase() < b[selector].toLowerCase()) {  return -1}
            else if(b[selector].toLowerCase() < a[selector].toLowerCase() )  { return 1}
            else{  return 0 }
        });
        setLessons(sorted)
        setSortOrder('desc')
        }
        if(sortOrder === 'desc')
        {
            const sorted = [...lessons].sort((a,b) => {
                if(a[selector].toLowerCase() > b[selector].toLowerCase()) {  return -1}
                else if(b[selector].toLowerCase() > a[selector].toLowerCase() )  { return 1}
                else{  return 0 }
            });
            setLessons(sorted)
            setSortOrder('asc')
        }
    }

    //Fetch lessons
    const getLessons = () => {
       
        Axios.get(Constants.BASE_URL + `api/getLessons/${props.userId}`).then((result) => {
            if (result.data.status) {
               
               const nonUploadedLessons = result.data.info.filter((item)=>{
                   return item.Is_Uploaded == 0;
                })
                
                setLessons(nonUploadedLessons)
                setDataFromApi(nonUploadedLessons)
            } else {
                setLessons([])
                setDataFromApi([])
            }
        })
    }

    //Create lessons
    const createLesson = (e) =>{

        e.preventDefault()
        setIsLoading(true)
        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
        let data = {
            userId: userID,
            class: newClass,
            subject: newSubject,
            chapter: newChapter,
            lesson: newLesson,
            assignedTo: assigned
        }

        Axios.post(Constants.BASE_URL + `api/CreateLesson`, data).then((result) => {
            setIsLoading(false)
            createLessonPopup();
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Lesson created succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                getLessons();

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to create Lesson. Please try again",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    //fetch content creators
    const getContentCreators = () => {
        Axios.get(Constants.BASE_URL + `api/getAllContentCretors/${props.userId}`).then((result) => {
            if (result.data.status) {
                setContentCreators(result.data.info);
            } else {
                setContentCreators([]);
            }
        })
    }

    //Assign lesson to content creator
    const onAssigningLesson = (e, lessonID) => {
        e.preventDefault()
        let data = {
            lessonId: lessonID,
            userId: e.target.value,
            
        }
        setIsLoading(true)
        Axios.post(Constants.BASE_URL + `api/AssignLesson`, data).then((result)=>{
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Lesson Assigned succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                 getLessons()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to Assign Lesson. Please try again",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }



    useEffect(() => {
        getLessons()
        getContentCreators();

        const interval=setInterval(()=>{
            getLessons()
            getContentCreators();
        },30000);
        return()=>clearInterval(interval)

    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Class",
            center: true,
            selector: (row) => row.Class,
            grow: 1
        },
        {
            name: "Subject",
            center: true,
            selector: (row) => row.Subject,
            grow: 1
        },
        {
            name: "Chapter",
            center: true,
            selector: (row) => row.Chapter,
            grow: 1
        },
        {
            name: "Lesson",
            center: true,
            selector: (row) =>row.Lesson,
            grow: 1
        },
        {
            name: "Status",
            center: true,
             selector: (row) => row.Status ,
            grow: 1
        },
        {
            name: "Actions",
            center: true,
            grow: 2,
            cell: row => {
                return (
                    <div className="actions">
                        <select className="whiteBGDropdown" value={row.Assigned_To} onChange={(e) =>onAssigningLesson(e, row.Id) }>
                            <option selected value='Unassigned'> Assign</option>
                            {
                                
                                contentCreators.map((contentCreator, index)=>{
                                    return(
                                        <option value={contentCreator.Id}>{contentCreator.FirstName}</option>
                                    )
                                })
                            }
                        </select>
                        
                    </div>
                )
            }
        }
    ]
 
    //Toggle create lesson popup
    const createLessonPopup = (e) =>{
        setShowModal(!showModal)
    }

    return (
        <div className="main-content">
            { isLoading ?<LoaderComp isLoading={isLoading} /> : null}
            <Header title="Assign Task" {...props} />
            <CreateLessonModal show={showModal} onHide={createLessonPopup} contentCreators={contentCreators} onAssigningLesson={onAssigningLesson} addClass={enteredClass} addSubject={enteredSubject} addLesson={enteredLesson} addChapter={enteredChapter} assignedTo={assignedTo} formSubmit={createLesson}/>
            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        <div className="col-7">
                            <label className="sort-btn" onClick={() => handleSort('Class')}>
                                <span className="filters" >Class</span>
                                <img src={sortOrder=='asc'? descImg:ascImg} className="sort-arrow" alt=''/>
                            </label>
                            <label className="sort-btn" onClick={() => handleSort('Subject')}>
                                <span className="filters" >Subject</span>
                                <img src={sortOrder=='asc'? descImg:ascImg} className="sort-arrow" alt=''/>
                            </label>
                            <label className="sort-btn" onClick={() => handleSort('Chapter')}>
                                <span className="filters" >Chapter</span>
                                <img src={sortOrder=='asc'? descImg:ascImg} className="sort-arrow" alt=''/>
                            </label>
                            <label className="sort-btn" onClick={() => handleSort('Lesson')}>
                                <span className="filters" >Lesson</span>
                                <img src={sortOrder=='asc'? descImg:ascImg} className="sort-arrow" alt=''/>
                            </label>
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="create-user-btn" onClick={createLessonPopup}  >
                                <span className="add-user">+ Lesson</span>
                            </label>
                            <label className="search-bar">
                                <input className="search" placeholder="Search" onChange={filterData}/>
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <DataTable
                    columns={columns}
                    data={lessons}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    persistTableHead
                    noDataComponent
                />
            </div>

        </div>
    )
}
export default AssignTask;