import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import EditParameterModal from "./editParameterModal";
import LoaderComp from "../../Loader";
import CreateParameterModal from "./createParameterModal";
import searchIcon from '../../../assets/images/searchIcon.png'
// import CreateUserModal from './createUser'


function Parameters(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [parameters, setParameters] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [showParam, setShowParam] = useState(false);
    const [updatedParameter,setUpdatedParameter] = useState('');
    const [parameterId,setParameterId] = useState(null);
    const [inputList, setInputList] = useState([""])
    const [paramFromApi, setParamFromApi] = useState('');



      //For Edit User
      const onParameterUpdate = (event) => {
        setUpdatedParameter(event.target.value)
    }
   
    const onParameterChange =(index,event) => {
        let data = [...inputList];
        data [index] = event.target.value;
        setInputList(data);
    }
    
    const filterData = (e) => {
        if(e.target.value =='')
        {
            setParameters(paramFromApi);
        }else
        {
            const filteredResult = paramFromApi.filter((item) => {
                return item.ParameterName.toLowerCase().includes(e.target.value.toLowerCase());
            })
            setParameters(filteredResult);
        }
    }


    const getParameters = () => {
        Axios.get(Constants.BASE_URL + `api/getParameters/${props.userId}`).then((result) => {
            if (result.data.status) {
                setParameters(result.data.info)
                setParamFromApi(result.data.info)
            } else {
                setParameters([])
                setParamFromApi([])
            }
        })
    }

    useEffect(() => {
        getParameters()
    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
        },
        {
            name: "Parameter Name",
            center: true,
            selector: (row) => row.ParameterName,
        },
        {
            name: "Created On",
            center: true,
            selector: (row) => <Moment format="DD-MMM-YYYY">
                {row.Created_On}
            </Moment>,
        },
        {
            name: "Last Modified",
            center: true,
            selector: (row) => <Moment format="DD-MMM-YYYY">
                {row.Last_Modified_On}
            </Moment>,
        },
        {
            name: "Actions",
            center: true,
            cell: row => {
                return (
                    <div className="actions">
                        <button className="btn-action" type="button" onClick={() => editParameterPopup(row.Id, row.ParameterName)}>Edit</button>
                        <button className="btn-action" type="button" onClick={() => deleteParameter(row.Id)}>Delete</button>
                    </div>
                )
            }
        }
    ]

    const createParameters = (e) => {
        e.preventDefault();
        setIsLoading(true)
        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
        let data = {
            userId: userID,
            parameterName: inputList
        }
        Axios.post(Constants.BASE_URL + `api/CreateParameter`, data).then((result) => {
            setIsLoading(false)
            setShowModal(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Parameter created succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                getParameters()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to create Parameter.Please try again",
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }


    const editParameter = (e) => {
        e.preventDefault();
        setIsLoading(true)
       
        let data = {
            parameterName: updatedParameter,
            parameterId: parameterId 
        }
      
        Axios.post(Constants.BASE_URL + `api/EditParameter`, data).then((result) => {
            setIsLoading(false);
            setShowParam(!showParam)
            if (result.data.status) {
               
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Parameter edited succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                getParameters()
            } else {
                
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to edit Parameter.Please try again",
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }


    const deleteParameter = (Id) => {
        Swal.fire({
            title: 'Do you really want to delete Parameter? ',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            showCloseButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                Axios.delete(Constants.BASE_URL + `api/deleteParameters/${Id}`).then((result) => {
                    setIsLoading(false);
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Parameter deleted successfully.',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        getParameters()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Error while deleting parameter. Please try again',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            }
        })
    }

    const createParameter = () => {
        setShowModal(!showModal)
    }

    const editParameterPopup = (rowId, paramName) => {
        setShowParam(!showParam)
       setUpdatedParameter(paramName);
       setParameterId(rowId);
    }

    return (
        <div className="main-content">
            <Header title="Parameter" {...props} />
            { isLoading ?<LoaderComp isLoading={isLoading} /> : null}
            <CreateParameterModal show={showModal} onHide={createParameter} onParameterChange={onParameterChange} onFormSubmit={createParameters}/>
            <EditParameterModal show={showParam} onHide={editParameterPopup} onParameterChange={onParameterUpdate}  onFormSubmit={editParameter} parameter={updatedParameter} />
            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        <div className="col-7">
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="create-user-btn" onClick={createParameter}>
                                <span className="add-user" >+ Parameter</span>
                            </label>
                            <label className="search-bar">
                                <input className="search" placeholder="Search" onChange={filterData} />
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <DataTable
                    columns={columns}
                    data={parameters}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    persistTableHead
                    noDataComponent
                />
            </div>

        </div>
    )
}
export default Parameters;