import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import UploadLessonModal from "./uploadLessonModal";
// import Moment from 'react-moment';
import ascImg from '../../../assets/images/sort-asc.png'
import descImg from '../../../assets/images/sort-desc.png'
import searchIcon from '../../../assets/images/searchIcon.png'
import { OverlayTrigger, Tooltip } from "react-bootstrap";


function Backlog(props) {

    const [backlogs,setBacklogs] = useState('')
    const [lessonID, setLessonId ] = useState('');
    const [showModal, setShowModal] =  useState(false);
    const [sortOrder, setSortOrder] = useState('asc')
    const [dataFromApi, setDataFromApi] = useState('')
    const[file,setFile] = useState(null);




    const getAllBacklogs = () => {
       
        Axios.get(Constants.BASE_URL + `api/getAllBacklogs/${props.userId}`).then((result) => {
            if (result.data.status) {
                setBacklogs(result.data.info)
                setDataFromApi(result.data.info)
            } else {
                setBacklogs([])
                setDataFromApi([])
            }
        })
    }


    //search Backlogs
    const filterData = (e) => {
        if (e.target.value == '') {
            setBacklogs(dataFromApi)
        } else {
            const filteredResult = dataFromApi.filter((item) => {

                return item.Lesson.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Subject.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Class.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Chapter.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.Status.toLowerCase().includes(e.target.value.toLowerCase());

            });
            setBacklogs(filteredResult);
        }
    }


    const onSelect = (e, lessonId) => {
        if(e.target.value === 'In Progress')
        {
            Axios.get(Constants.BASE_URL + `api/setInProgress/${lessonId}`).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Status Updated succesfully",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    //  setIsLoading(false)
                    getAllBacklogs()
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Unable to Update Status. Please try again",
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }else if(e.target.value === "Upload"){

            togglePopup(lessonId)
            
        }
    }
    
    const togglePopup = (id) => {

        setShowModal(!showModal);
        setLessonId(id);
    }


    useEffect(() => {
        getAllBacklogs();
       
    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Class",
            center: true,
            selector: (row) => row.Class,
            grow: 1
        },
        {
            name: "Subject",
            center: true,
            selector: (row) => row.Subject,
            grow: 1
        },
        {
            name: "Chapter",
            center: true,
            selector: (row) => row.Chapter,
            grow: 1
        },
        {
            name: "Lesson",
            center: true,
            selector: (row) =>row.Lesson,
            grow: 1
        },
        {
            name: "Status",
            center: true,
            grow: 1,
            cell: row => 
            {
                return(
                    row.Status !== "Rejected" ? row.Status :
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{row.Rejection_Reason}</Tooltip>}
                        >
                            <label>{row.Status}</label>
                        </OverlayTrigger>
                )
            }
        },
        {
            name: "Actions",
            center: true,
            grow: 2,
            cell: row => {
                return (
                    <div className="actions" >
                         <select className="whiteBGDropdown" defaultValue={row.Status}  onChange={(e) =>onSelect(e,row.Id)}>
                            <option selected value="">To Do</option>
                            <option value="In Progress">Progress</option>
                            <option value="Upload">Upload</option>
                        </select>
                    </div>
                )
            }
        }
    ]

    const fileUpload = (e) =>{
        
        e.preventDefault();
       
        setFile(e.target.files[0]);
    }

    const submitFile = () => {

        const extension = file.name.split(".").pop();
        
        if(extension !== 'exp'){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only .exp file",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        

        const fromData = new FormData();
        fromData.append("fileUpload", file);
        fromData.append("lessonId", lessonID);


        Axios.post(Constants.BASE_URL + "api/uploadLesson", fromData).then((result) => {
            if (result.data.status) {
                setShowModal(false);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "File Updated succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                localStorage.removeItem("details");
                getAllBacklogs()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Error while Uploading file",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

   

    return (
        <div className="main-content">
             <UploadLessonModal show={showModal} onHide={togglePopup} upload={fileUpload} formSubmit={submitFile}/>
            <Header title="Backlog" {...props} />
            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        <div className="col-7">
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="search-bar preview-lesson">
                                <input className="search" placeholder="Search" onChange={filterData}/>
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <DataTable
                    columns={columns}
                    data={backlogs}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    persistTableHead
                    noDataComponent
                />
            </div>

        </div>
    )
}
export default Backlog;