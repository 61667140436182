import { useState } from "react";
import { Modal } from "react-bootstrap";

function CreateParameterModal(props) {

    const [inputList, setInputList] = useState(['']);

    const createNewInput = () => {
        let newField = ''
        setInputList([...inputList, newField])
    }

    return (
        <Modal {...props} centered  scrollable>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Parameter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                    <form className="create-user-form" onSubmit={props.onFormSubmit}>
                        {
                            inputList.map((input,index) => {
                                return(
                                    <div className="form-group" key={index}>
                                        <label className="form-label">Parameter:</label>
                                        <input className="form-control" type="text" id="name" value={props.parameter} onChange={(event) => props.onParameterChange(index,event)} autoFocus required />
                                    </div>
                                )
                            })
                        }
                        <div className="d-flex justify-content-end">
                            <button className="add-input-fields" type="button" onClick={createNewInput}>+ New</button>
                        </div>
                    
                        <div className="form-submit d-flex justify-content-center">
                            <button className="btn-submit" type="submit">Submit</button>
                        </div>
                    </form>
            </Modal.Body>
        </Modal>
    );
}
export default CreateParameterModal;