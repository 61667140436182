
import { Modal } from "react-bootstrap";

function RejectionInfoModal(props) {
    
    return (
        <Modal {...props} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add a comment 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={props.submitData}>
                    <textarea className="reject-info" type="text" placeholder="Write a comment" onChange={(e) => props.changeValue(e.target.value, props.setInfo)} required></textarea>
                    <button type="submit" className="btn-submit">Submit</button>
                </form>
            </Modal.Body>
        </Modal>
    );
}
export default RejectionInfoModal;