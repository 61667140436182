import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import CreateUserModal from './createUser'
import Moment from 'react-moment';
import EditUserModal from "./editUser";
import LoaderComp from "../../Loader";
import ascImg from '../../../assets/images/sort-asc.png'
import descImg from '../../../assets/images/sort-desc.png'
import searchIcon from '../../../assets/images/searchIcon.png'



function AdminUsers(props) {

    const [isLoading, setIsLoading] = useState(false);

    const [users, setUsers] = useState('');
    const [dataFromApi, setDataFromApi] = useState('');
    const [showModal, setShowModal] = useState(false)
    const [newUserName, setNewUserName] = useState([]);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserRole, setNewUserRole] = useState('');

    const [showEditUser, setShowEditUser] = useState(false);

    const [rowId, setRowId] = useState(null);
    const [updatedName, setUpdatedName] = useState('');
    const [updatedEmail, setUpdatedEmail] = useState('');
    const [updatedUserRole, setUpdateUserdRole] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const enteredUserName = (event) => {
        setNewUserName(event.target.value)
    }
    const enteredUserEmail = (event) => {
        setNewUserEmail(event.target.value)
    }
    const enteredUserRole = (event) => {
        setNewUserRole(event.target.value)
    }

    //For Edit User
    const onNameUpdate = (event) => {
        setUpdatedName(event.target.value)
    }
    const onEmailUpdate = (event) => {
        setUpdatedEmail(event.target.value)
    }
    const onRoleUpdate = (event) => {
        setUpdateUserdRole(event.target.value)
    }


    //search user
    const filterData = (e) => {
        if (e.target.value == '') {
            setUsers(dataFromApi)
        } else {
            const filteredResult = dataFromApi.filter((item) => {

                return item.FirstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        item.Email.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        item.CreatedBy_Name.toLowerCase().includes(e.target.value.toLowerCase());

            });
            setUsers(filteredResult);
        }
    }


    //sort User
    const handleSort = (selector) => {
        if (sortOrder === 'asc') {
            const sorted = [...users].sort((a, b) => {
                if (a[selector].toLowerCase() < b[selector].toLowerCase()) { return -1 }
                else if (b[selector].toLowerCase() < a[selector].toLowerCase()) { return 1 }
                else { return 0 }
            });
            setUsers(sorted)
            setSortOrder('desc')
        }
        if (sortOrder === 'desc') {
            const sorted = [...users].sort((a, b) => {
                if (a[selector].toLowerCase() > b[selector].toLowerCase()) { return -1 }
                else if (b[selector].toLowerCase() > a[selector].toLowerCase()) { return 1 }
                else { return 0 }
            });
            setUsers(sorted)
            setSortOrder('asc')
        }
    }



    //Create Users
    const createUsers = (e) => {

        e.preventDefault();
        setIsLoading(true)

        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
        let data = {
            userId: userID,
            userName: newUserName,
            email: newUserEmail,
            userRole: newUserRole
        }

        Axios.post(Constants.BASE_URL + `api/CreateUser`, data).then((result) => {
            setIsLoading(false);
            setShowModal(false);
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "User created succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                getUsers();

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: result.data.info,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    //Edit users
    const editUser = (e) => {
        e.preventDefault();
        setIsLoading(true)

        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID;

        let data = {
            userName: updatedName,
            userRole: updatedUserRole,
            email: updatedEmail,
            updatedBy: userID,
            userId: rowId,
        }

        Axios.post(Constants.BASE_URL + `api/EditUser`, data).then((result) => {

            setIsLoading(false);
            setShowEditUser(false)
            if (result.data.status) {

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "User edited succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })

                getUsers();
            } else {

                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to edit user.Please try again",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    //Get users
    const getUsers = () => {

        Axios.get(Constants.BASE_URL + `api/getAllAdminUsers/${props.userId}`).then((result) => {

            if (result.data.status) {
                setUsers(result.data.info);
                setDataFromApi(result.data.info);
            } else {
                setUsers([])
                setDataFromApi([]);
            }
        })
    }

    useEffect(() => {
        getUsers()
    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
        },
        {
            name: "Name",
            center: true,
            selector: (row) => row.FirstName,
        },
        {
            name: "Email",
            center: true,
            selector: (row) => row.Email,
        },
        {
            name: "Role",
            center: true,
            selector: (row) => { return (row.Role_Id === 1 ? "Admin" : 
                                row.Role_Id === 2 ? "Product Head" : 
                                row.Role_Id === 3 ? "Teacher" : 
                                row.Role_Id === 4 ? "Content Creator":'' )}
        },
        {
            name: "Created By",
            center: true,
            selector:(row) => row.CreatedBy_Name

        },
        {
            name: "Created On",
            center: true,
            selector: (row) => <Moment format="DD-MMM-YYYY">
                {row.Created_On}
            </Moment>,
        },
        {
            name: "Actions",
            center: true,
            grow: 2,
            cell: row => {
                return (
                    <div className="actions">
                        <button className="btn-action" type="button" onClick={() => editUserPopup(row.FirstName, row.Email, row.Role_Id, row.Id)}>Edit</button>
                        <button className="btn-action" type="button" onClick={() => deleteUser(row.Id)}>Delete</button>
                    </div>
                )
            }
        }
    ]

    const deleteUser = (Id) => {

        Swal.fire({
            title: 'Do you really want to delete User? ',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            showCloseButton: true,
        }).then((result) => {
            setIsLoading(true)
            if (result.isConfirmed) {
                Axios.delete(Constants.BASE_URL + `api/deleteProductHeadUsers/${Id}`).then((result) => {
                    setIsLoading(false)
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'User deleted successfully.',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        getUsers()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Error while deleting user. Please try again',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            } else {
                setIsLoading(false)
            }
        })
    }

    const createUser = () => {
        setShowModal(!showModal)
    }
    const editUserPopup = (firstName, email, role, rowId) => {
        setShowEditUser(!showEditUser)
        setUpdatedName(firstName);
        setUpdatedEmail(email)
        setUpdateUserdRole(role)
        setRowId(rowId)
    }



    return (
        <div className="main-content">
            <Header title="User" {...props} />
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
            <CreateUserModal show={showModal} onHide={createUser} onNameChange={enteredUserName} onEmailChange={enteredUserEmail} onRoleChange={enteredUserRole} onFormSubmit={createUsers} />
            <EditUserModal show={showEditUser} onHide={editUserPopup} onNameUpdate={onNameUpdate} onEmailUpdate={onEmailUpdate} onRoleUpdate={onRoleUpdate} onFormSubmit={editUser} firstName={updatedName} email={updatedEmail} roleId={updatedUserRole} />

            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        <div className="col-7">
                            <label className="sort-btn" onClick={(e) => { handleSort('FirstName') }}>
                                <span className="filters">Name</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={(e) => { handleSort('Email') }}>
                                <span className="filters">Email</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={(e) => { handleSort('Created_On') }}>
                                <span className="filters">Created On </span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='sort' />
                            </label>
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="create-user-btn" onClick={createUser}>
                                <span className="add-user" >+ User</span>
                            </label>
                            <label className="search-bar">
                                <input className="search" placeholder="Search" onChange={filterData} />
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <DataTable
                    columns={columns}
                    data={users}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    persistTableHead
                    noDataComponent
                />
            </div>

        </div>
    )
}
export default AdminUsers;