import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Header from "../../Header";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import PreViewLessonModal from "./previewLessonModal";
import ascImg from '../../../assets/images/sort-asc.png'
import descImg from '../../../assets/images/sort-desc.png'
import searchIcon from '../../../assets/images/searchIcon.png'
import LoaderComp from "../../Loader";
import RejectionInfoModal from "./rejectionInfoModal";

function PreviewLesson(props) {
    const [lessons, setLessons] = useState([])
    const [showModal, setShowModa] = useState(false)
    const [lessonId, setLessonId] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [dataFromApi, setDataFromApi] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [rejectedInfo, setRejectedInfo] = useState('');
    const [rejectedLessonId, setRejectedLessonId] = useState('');

    const changeValue = (value, setFunc) => {
        setFunc(value)
    }


    const getLessons = () => {
        Axios.get(Constants.BASE_URL + `api/getAllUploadedLessons/${props.userId}`).then((result) => {
            if (result.data.status) {
                setLessons(result.data.info)
                setDataFromApi(result.data.info)
            } else {
                setLessons([])
                setDataFromApi([])
            }
        })
    }

    //search Lesson
    const filterData = (e) => {
        if (e.target.value == '') {
            setLessons(dataFromApi)
        } else {
            const filteredResult = dataFromApi.filter((item) => {

                return item.Lesson.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        item.Class.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        item.Subject.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        item.Chapter.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        item.Status.toLowerCase().includes(e.target.value.toLowerCase()) ;

            });
            setLessons(filteredResult);
        }
    }

    //sort Lessons
    const handleSort = (selector) => {
        if (sortOrder === 'asc') {
            const sorted = [...lessons].sort((a, b) => {
                if (a[selector].toLowerCase() < b[selector].toLowerCase()) { return -1 }
                else if (b[selector].toLowerCase() < a[selector].toLowerCase()) { return 1 }
                else { return 0 }
            });
            setLessons(sorted)
            setSortOrder('desc')
        }
        if (sortOrder === 'desc') {
            const sorted = [...lessons].sort((a, b) => {
                if (a[selector].toLowerCase() > b[selector].toLowerCase()) { return -1 }
                else if (b[selector].toLowerCase() > a[selector].toLowerCase()) { return 1 }
                else { return 0 }
            });
            setLessons(sorted)
            setSortOrder('asc')
        }
    }


    const setLessonStatus = (lessonStatus, lessonID) => {

        Swal.fire({
            title: 'Accept the lesson?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) =>{
            if (result.isConfirmed){
                setIsLoading(true)

                let data = {
                    lessonId: lessonID,
                    status: lessonStatus,
                }
                Axios.post(Constants.BASE_URL + `api/SetLessonStatus`, data).then((result) => {
                    setIsLoading(false)
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Status Updated succesfully",
                            showConfirmButton: false,
                            timer: 1000
                        })
                        getLessons()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to Update Status. Please try again",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            }
        })

        
    }

    const rejectLessonHandle = (lessonID) =>{
        setIsRejected(!isRejected)
        setRejectedLessonId(lessonID)
    }
    const submitForm = (e) =>{
        e.preventDefault()
        
        let data = {
            lessonId: rejectedLessonId,
            status: 'Rejected',
            rejectedInfo: rejectedInfo
        }

        Axios.post(Constants.BASE_URL + `api/rejectLesson`, data).then((result)=>{
            setIsRejected(false)

            if(result.data.status){
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Status Updated succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                getLessons()
            } else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to Update Status. Please try again",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    

    const deleteLesson = (lessonID, location) =>{
        let filename = location.split('lessons\\')[1]

        Swal.fire({
            title: 'Are you sure you want to delete Lesson?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'

        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    lessonId: lessonID,
                    location: filename
                }

                Axios.post(Constants.BASE_URL + `api/deleteLesson`, data).then((result) => {
                    
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: result.data.info,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        getLessons()
                        
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Something went wrong. Please try again',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                   
                });
            }
        })
    }


    useEffect(() => {
        getLessons()
        const interval=setInterval(()=>{ getLessons()},30000);
        return()=>clearInterval(interval)
    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#A9DABC',
            },
        },
        headCells: {
            style: {
                color: '#000000',
                fontSize: '20px',
                fontWeight: '700',
                paddingBottom: '20px',
                paddingTop: '50px'
            },
        },
        rows: {
            style: {
                backgroundColor: '#A9DABC',
                fontSize: '17px',
                color: '#707070',
            },
        },
        pagination: {
            style: {
                border: 'none',
                backgroundColor: '#A9DABC',
                fontSize: '17px'
            },
        },
    };

    const columns = [
        {
            name: "Sl.No",
            center: true,
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Class",
            center: true,
            selector: (row) => row.Class,
            grow: 1
        },
        {
            name: "Subject",
            center: true,
            selector: (row) => row.Subject,
            grow: 1
        },
        {
            name: "Chapter",
            center: true,
            selector: (row) => row.Chapter,
            grow: 1
        },
        {
            name: "Lesson",
            center: true,
            selector: (row) => row.Lesson,
            grow: 1
        },
        {
            name: "Assigned To",
            center: true,
            selector: (row) => row.Name,
            grow: 1
        },
        {
            name: "Status",
            center: true,
            selector: (row) => row.Is_Uploaded === 1 && (row.Status === "In Progress" || row.Status === "ToDo")? "Uploaded" : row.Status,
            grow: 1
        },
        {
            name: "Actions",
            center: true,
            grow: 2,
            cell: row => {
                return ( row.Status !== "Accepted"?
                    <div className="actions">
                        <button className={row.Status === 'Accepted'?'btn-action accept':'btn-action'} type="button" onClick={() => setLessonStatus('Accepted', row.Id)}>Accept</button>
                        <button className={row.Status === 'Rejected' ? 'btn-action reject' : 'btn-action'} type="button" onClick={() => rejectLessonHandle(row.Id)}>Reject</button>
                    </div>
                    :
                    <button className="btn-action" type="button" onClick={() => deleteLesson(row.Id, row.Link)}>Delete</button>
                )
            }
        },
        {
            name: "Preview",
            center: true,
            cell: row =>{
                return <button className="btn-action preview" type="button" onClick={() => togglePreviewPopup(row.Id)}>Play</button>
            }
        }
    ]
    
    const togglePreviewPopup = (rowId) => {
        setShowModa(!showModal);
        setLessonId(rowId);
    }

    return (
        <div className="main-content">
             { isLoading ?<LoaderComp isLoading={isLoading} /> : null}
            <PreViewLessonModal show={showModal} onHide={togglePreviewPopup} lessonID={lessonId} />
            <RejectionInfoModal show={isRejected} onHide={rejectLessonHandle} changeValue={changeValue} submitData={submitForm} setInfo={setRejectedInfo}/>
            <Header title="Preview Lesson" {...props} />
            <div className="row">
                <div className="col-12 filtersSection">
                    <div className="row">
                        <div className="col-7">
                            <label className="sort-btn" onClick={() => handleSort('Class')}>
                                <span className="filters" >Class</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={() => handleSort('Subject')}>
                                <span className="filters">Subject</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={() => handleSort('Chapter')}>
                                <span className="filters">Chapter</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                            <label className="sort-btn" onClick={() => handleSort('Lesson')}>
                                <span className="filters">Lesson</span>
                                <img src={sortOrder == 'asc' ? descImg : ascImg} className="sort-arrow" alt='' />
                            </label>
                        </div>
                        <div className="col-5 rigthAlign">
                            <label className="search-bar preview-lesson">
                                <input className="search" placeholder="Search" onChange={filterData} />
                                <span></span>
                                <img className="searchIcon" src={searchIcon} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row data-container-wrapper">
                <DataTable
                    columns={columns}
                    data={lessons}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    persistTableHead
                    noDataComponent
                />
            </div>

        </div>
    )
}
export default PreviewLesson