import React from 'react';
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
import Swal from 'sweetalert2'

class ResetPassword extends React.Component {
    constructor() {
        super()
        this.state = {
            uniqueId: "",
            password: "",
            rePassword: "",
            title: ""
        }
    }

    componentDidMount() {
        var url = this.props.location.pathname.split("/");
        let uniqueId = url[url.length - 1]
        let pageType = url[url.length - 2]
        let title = ""
        if (pageType === "forgetPassword") {
            title = "Password Recovery"
        } else {
            title = "Set Password"
        }

        this.setState({
            uniqueId: uniqueId,
            title: title
        })
    }

    changeValue = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    resetPassword = (e) => {
        e.preventDefault();
        if (this.state.password.trim() === "" || this.state.rePassword.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please enter both the fields",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.password === this.state.rePassword) {
            let data = {
                uniqueId: this.state.uniqueId,
                password: this.state.password
            }

            Axios.put(Constants.BASE_URL + "api/resetPassword", data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Password changed sucessfully",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.props.history.push('/Login');
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
            });
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Both Passwords should be same",
                showConfirmButton: false,
                timer: 1000
            })
        }
    }
    render() {
        return (
            <div className="resetPasswordScreen commonSection">
                <div className="resetPassword resetPasswordExtraStyling">
                    <p className="resetPassTitle">{this.state.title}</p>
                    <p className="resetPassSubTitle">Set a new password for your account.</p>
                    <form className="signin_form" onSubmit={this.resetPassword}>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor='email' className='inputLabels'>Enter New Password</label><br />
                                <input type="password" className="newPassword inputFields noOutline noBorder" value={this.state.password} onChange={(e) => this.changeValue("password", e)} required />
                            </div>
                            <div className="col-12">
                                <label htmlFor='email' className='inputLabels'>Confirm Password</label><br />
                                <input type="password" className="confirmPassword inputFields noOutline noBorder" value={this.state.rePassword} onChange={(e) => this.changeValue("rePassword", e)} required />
                            </div>
                        </div>
                        <div className="row submitButtonSection" >
                            <div className="col text-center">
                                <button type="submit" className="btn loginButton" >Save & Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default ResetPassword