import React from 'react';
import Axios from 'axios';
import * as Constants from '../../assets/helpers/constants'
import Logo from '../../assets/images/BaethoLogo.png'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

class LeftPanel extends React.PureComponent {
    constructor() {
        super()
        this.state = {
            menuItems: []
        }
    }

    componentDidMount = () => {
        Axios.get(Constants.BASE_URL + `api/getSidePanelDetails/${this.props.roleId}`).then((result) => {
            if (result.data.status && result.data.info.length > 0) {
                this.setState({
                    menuItems: result.data.info
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Error while fetching data. Please try again",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    render() {
        return (
            <div className="left-sidebar">
                <div className="row">
                    <div className="col-12">
                        <img src={Logo} alt="logo" className='baethoLogo' />
                    </div>
                    <div className="col-12 menuList">
                        <ul className="menu-items">
                            {
                                this.state.menuItems.map((item) => {
                                    return (
                                        <Link key={item.Id} to={item.Link} onClick={() => this.props.changeLink(item.Name)}>
                                            <li className={this.props.activeLink === item.Name || window.location.href.toLowerCase().includes(item.Link.toLowerCase()) ? 'menu-item active' : 'menu-item'} >
                                                <span className='content'><img className='menu-icons' src={item.Icon} alt={item.Name} />{item.Name}</span>
                                            </li>
                                        </Link>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default LeftPanel;
