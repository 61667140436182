import React from 'react';
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
import LoaderComp from '../Loader'
import Swal from 'sweetalert2'

class Recovery extends React.Component {
    constructor() {
        super()
        this.state = {
            recoveryEmail: "",
            isLoading: false
        }
    }
    sendEmail = (e) => {
        e.preventDefault();
        if (this.state.recoveryEmail !== "") {
            this.setState({
                isLoading: true
            }, () => {
                let data = {
                    email: this.state.recoveryEmail
                }
                Axios.post(Constants.BASE_URL + "api/sendEmail", data).then((result) => {
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Email Triggered succesfully",
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.setState({
                            isLoading: false
                        })
                        this.props.history.push(Constants.Login)
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: result.data.info,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.setState({
                            isLoading: false
                        })
                    }
                });
            })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Email field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isLoading: false
            })
        }
        return false
    }

    setEmail = (e) => {
        this.setState({
            recoveryEmail: e.target.value
        })
    }

    render() {
        return (
            <div className='recoveryScreen commonSection'>
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="recover recoverExtraStyle pl-5 recoveryDetails">
                    <p className="recoveryText">RECOVERY</p>
                    <p className="recoverySubTitle">Enter your email and we send you a password reset link.</p>
                    <form className="signin_form" onSubmit={this.sendEmail}>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor='email' className='inputLabels'>Email</label><br />
                                <input type="email" id='email' className="inputFields noOutline noBorder" value={this.state.recoveryEmail} onChange={(e) => this.setEmail(e)} required />
                            </div>
                        </div>
                        <div className="row submitButtonSection">
                            <div className="col text-center">
                                <button type="submit" className="btn loginButton" >Send Request</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Recovery