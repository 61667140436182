import React from 'react';
import AddStudent from './addStudent';
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
// import Player from '../Player/player';
import AllClassroomStudents from './AllClassRoomStudents';
import UserImg from '../../assets/images/User.png'
import MailImg from '../../assets/images/Mail.png'
import PhoneImg from '../../assets/images/Phone.png'
import Swal from 'sweetalert2'

const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class ClassRoom extends React.Component {
    constructor() {
        super()
        this.state = {
            collectedDetails: false,
            url: null,
            finalUrl: null,
            lessonId: null,
            studentList: [],
            studentId: null,
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            classId: null,
            shouldPlayPlayer: false,
            mailErr: null,
            errMsg: null,
            isLoading: false,
            isDisabled: false
        }
    }

    componentDidMount() {
        const urlParams = window.location.href
        let values = urlParams.split("lesson=")
        let collectedDetails = false
        try {
            if (localStorage.getItem("collectedDetails") !== null) {
                if(localStorage.getItem("user") !== null){
                    collectedDetails = true;
                }else{
                    collectedDetails = false;
                }
            }
            this.setState({
                lessonId: values[1],
                classId: (Math.random() + 1).toString(36).substring(7),
                collectedDetails: collectedDetails
            })
        } catch(e)
        {
            console.log("Catching in localstorage")
            // console.log(e)
            this.setState({
                lessonId: values[1],
                classId: (Math.random() + 1).toString(36).substring(7),
                collectedDetails: true
            })
        }
    }
        

    // componentDidMount() {
    //     const urlParams = window.location.href
    //     let values = urlParams.split("lesson=")
    //     let collectedDetails = false

    //     try {
    //         console.log("Trying localstorage")
    //         if (window.localStorage.getItem("collectedDetails") !== null) {
    //             collectedDetails = window.localStorage['collectedDetails'];
    //         }
    //         this.setState({
    //             lessonId: values[1],
    //             classId: (Math.random() + 1).toString(36).substring(7),
    //             collectDetails: collectedDetails
    //         })
    //     } catch (e) {
    //         console.log("Catching in localstorage")
    //         console.log(e)
    //         this.setState({
    //             lessonId: values[1],
    //             classId: (Math.random() + 1).toString(36).substring(7),
    //             collectDetails: collectedDetails
    //         })
    //     }

    //     // // if (window.localStorage.getItem("collectedDetails") !== null) {
    //     // //     collectedDetails = window.localStorage['collectedDetails'];
    //     // // }
    //     // this.setState({
    //     //     lessonId: values[1],
    //     //     classId: (Math.random() + 1).toString(36).substring(7),
    //     //     collectedDetails: collectedDetails
    //     // })
    // }

    addStudentHandler = (studentEmail, studentName) => {
        // console.log('std length',this.state.studentList)
        if(this.state.studentList.length>8){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Only 9 students can be added.',
                showConfirmButton: false,
                timer: 1000
            })
            // this.setState({
            //     errMsg:"Please add only 9 students"
            // })
            return
        }

        let data = {
            firstName:studentName,
            studentId: studentEmail + "-" + studentName,
            classId: this.state.classId,
            type: "Student",
            email: studentEmail,
            lessonId: this.state.lessonId,
            teacherName: localStorage.getItem("user")
        }
        this.setState({ isLoading: true })
        Axios.post(Constants.BASE_URL + `api/createClassRoomURL`, data).then((result) => {
            this.setState({ isLoading: false,errMsg:null })
            
            if (result.data.status) {
                this.setState((previousState) => {
                    return {
                        collectedDetails: true,
                        studentList: [...previousState.studentList, { name: studentName, email: studentEmail }]
                    }
                }, () => {
                    this.getStudents()
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "User already registered for the Class..",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    addTeacher = (e) => {
        e.preventDefault()
        
        let externalSource = "Null";
        let param = window.location.href.split("/ClassRoom")
        externalSource = new URLSearchParams(param[1]).get("utm_source")

        this.setState({
            isDisabled: true
        },()=>{
            if (this.state.firstName.trim().length === 0 || this.state.phone.trim() === 0 || this.state.email.trim().length === 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'All fields are mandatory.',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isDisabled: false
                })
                return 
            }
            if (this.state.phone.length < 8 || this.state.phone.length > 12) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Phone No. must be greater than 8 & less than 12 digits',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isDisabled: false
                })
                return
            }
            if (regEx.test(this.state.email) === false) {
                
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Please enter a valid email!',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isDisabled: false
                });
                return
            }
            localStorage.setItem("user", this.state.firstName)
            if (this.state.firstName != "") {
                let data = {
                    studentId: this.state.firstName + "-" + this.state.phone,
                    classId: this.state.classId,
                    type: "Teacher",
                    lessonId: this.state.lessonId,
                    firstName: this.state.firstName,
                    email: this.state.email,
                    phone: this.state.phone,
                    source: "Assisted",
                    srcParam: externalSource
                }
                Axios.post(Constants.BASE_URL + `api/createClassRoom`, data).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            url: result.data.url,
                            finalUrl: `${Constants.WebSiteUrl}oneToManyClassRoom?link=${Constants.ApplicationUrl}ClassRoom?url=${result.data.url}`,
                            collectedDetails: true,
                            isDisabled: false
                        }, () => {
                            try {
                                console.log("Add teacher Trying")
                                window.localStorage['collectedDetails'] = true
                            } catch (e) {
                                console.log("Add teacher Catching")
                                console.log(e)
                            }

                        })
                    } else {
                        console.log("Error", result)
                        this.setState({
                            isDisabled: false
                        })
                    }
                })
            } else {
                this.setState({
                    collectedDetails: false,
                    isDisabled: false
                })
            }
        })

        
    }

    changeValue = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    playPlayer = () => {

        if(this.state.studentList.length<1){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Add atleast one student to start lesson.',
                showConfirmButton: false,
                timer: 1000
            })
            // this.setState({
            //     errMsg:"Please add atleast one student to start lesson"
            // })
            return
        }

        let data = {
            classId: this.state.classId
        }
        Axios.post(Constants.BASE_URL + `api/setStudentsStatusOfClass`, data).then((result) => {
            if (result.data.status) {
                this.setState({
                    shouldPlayPlayer: true,
                    collectedDetails: true
                })
            } else {
                console.log("Error in updating Interactive status of student")
            }
        })
    }

    getStudents = () => {
        let data = {
            classId: this.state.classId
        }
        Axios.post(Constants.BASE_URL + `api/getStudentsOfClass`, data).then((result) => {
            if (result.data.status) {
                this.setState({
                    studentList: result.data.result
                })
            } else {
                this.setState({
                    studentList: []
                })

            }
        })
    }

    render() {
        return (
            <div className='classroom-bg'>
                {/* <img className='baetho-logo' src={baethoLogo} /> */}
                {!this.state.collectedDetails && !this.state.shouldPlayPlayer && <div>
                    <div className="contact-form-wrapper-active">
                        <form className="contact-form" onSubmit={this.addTeacher}>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <img src={UserImg} className="user-icon" alt="user" />
                                    <input id="fname" className="pl-0" placeholder="Name" minLength="3" maxLength="50" title='min 4 characters' type="text" onChange={(e) => this.changeValue(e.target.value, "firstName")} required />
                                </div>
                            </div>
                            <img src={MailImg} className="mail-icon" alt="mail" />
                            <input id="emailId" placeholder="Email" type="email" minLength="4" maxLength="50" onChange={(e) => this.changeValue(e.target.value, "email")} required />
                            <img src={PhoneImg} className="phone-icon .img-fluid" alt="phone" />
                            <input id="phone" placeholder="Phone" type="number" onChange={(e) => this.changeValue(e.target.value, "phone")} required />
                            <div className="contact-btn">
                                <button className="btn" type="submit" disabled={this.state.isDisabled}>Submit</button>
                            </div>
                        </form>
                        {this.state.mailErr && (
                            <span className='mail-error'>{this.state.mailErr}</span>
                        )}
                    </div>
                    <div className='fill-details'>
                        <h1>Please Fill in Your Details</h1>
                    </div>

                </div>}
                {this.state.collectedDetails && !this.state.shouldPlayPlayer && <AddStudent classId={this.state.classId} onAddStudent={this.addStudentHandler} studentList={this.state.studentList} loading={this.state.isLoading} playPlayer={this.playPlayer} joinStatus={this.getStudents} lessonID={this.state.lessonId} errorMsg={this.state.errMsg} />}
                {this.state.shouldPlayPlayer && <AllClassroomStudents lessonId={this.state.lessonId} url={this.state.url} classId={this.state.classId} />}
            </div>
        )
    }
}

export default ClassRoom