import React, { useEffect, useState } from 'react';
import * as Constants from '../../assets/helpers/constants'

const Player = (props) => {

        const [playerwidth, setPlayerWidth] = useState(0)
        const [playerHeight, setPlayerHeight] = useState(0)
        const [showDownloading, setShowDownloading] = useState(true)
        const [url, setUrl] = useState("")
        const aspectRatio = 1.777777778;
        useEffect(() => {
               //window.onorientationchange = function () { window.location.reload(); }
                let url = props.url
                let type = props.type;
                let urlParmas = url.split("&")
                let baseUrl = urlParmas[0]
                // let widthData = urlParmas[1]
                // let heightData = urlParmas[2]
                // let width = window.innerWidth * 0.75
                // let height = window.innerHeight * 0.85
                let windowWidth;
                let windowHeight;
                if (type == 'onlyPlayer') {
                        console.log('window.innerWidth', window.innerWidth);
                        console.log('window.innerHeight', window.innerHeight);
                        if (window.innerHeight > window.innerWidth){
                                windowWidth = window.innerHeight * 0.95;
                                windowHeight = window.innerWidth * 0.80;
                        }else{
                                windowWidth = window.innerWidth * 0.95;
                                windowHeight = window.innerHeight * 0.95;
                        }
                        console.log('windowWidth', windowWidth);
                        console.log('windowHeight', windowHeight);
                }
                else {
                        windowWidth = window.innerWidth * 0.85 * 0.75;
                        windowHeight = window.innerHeight * 0.87 * 0.85;
                }

                // console.log("windowWidth / windowHeight", windowWidth / windowHeight)
                if (windowWidth / windowHeight < aspectRatio) {
                        setPlayerWidth(windowWidth);
                        setPlayerHeight(windowWidth / aspectRatio);
                        setUrl(`${baseUrl}&width=${windowWidth}&height=${windowWidth / aspectRatio}`)
                }
                else {
                        setPlayerWidth(windowHeight * aspectRatio);
                        setPlayerHeight(windowHeight);
                        setUrl(`${baseUrl}&width=${windowHeight * aspectRatio}&height=${windowHeight}`)
                }

                setInterval(() => {
                        setShowDownloading(false)
                },5000)
                // console.log("window.innerHeight", window.innerHeight, "window.innerWidth", window.innerWidth)
                // if (window.innerWidth > window.innerHeight) {
                //         setPlayerWidth(parseFloat(width));
                //         setPlayerHeight(parseFloat(height));
                //         setUrl(`${baseUrl}&width=${parseFloat(width)}&height=${parseFloat(height)}`)

                // } else {
                //         setPlayerWidth(parseFloat(height));
                //         setPlayerHeight(parseFloat(width));
                //         setUrl(`${baseUrl}&width=${parseFloat(height)}&height=${parseFloat(width)}`)
                // }
                // console.log("Player screen in Revvster  Width=> ", width, typeof (width),playerwidth)
                // console.log("Player screen in Revvster  Height=> ", height, typeof (height),playerHeight)
        }, [])

        const playerDimensionNormal = {
                width: playerwidth,
                height: playerHeight,
                marginLeft: 'auto',
                marginRight: 'auto',
        }

        return (
                <div className='playerIframe'>
                        <div className='ShowTurnPhone'>
                                Please view in LandScape Mode
                        </div>

                        {showDownloading ?
                                <div className='downloadScreen' style={{width:'100%', position: "absolute", top: playerHeight / 2, textAlign: 'center'}}>
                                        <p style={{textAlign: "center"}}>Loading...</p>
                                </div> : null}
                        <div style={playerDimensionNormal} className="playerOutline">
                                <iframe className='iframeDiv' width="100%" height="100%" src={url} title="description" ></iframe>
                        </div>
                </div>
        )
}

export default Player