
// export const BASE_URL = 'https://baethoedu.com/' // Production Server URl
// export const WebSiteUrl = 'https://education.baetho.com/#/'
// export const WebSiteUrl2 = 'https://www.education.baetho.com/#/'
// export const ApplicationUrl = 'https://baethoedu.com/#/'

// export const BASE_URL = 'http://localhost:8090/' // Local Server 
// export const WebSiteUrl = 'http://localhost:3001/#/'
// export const ApplicationUrl = 'http://localhost:3000/#/'

export const BASE_URL = 'http://122.248.229.117:8090/' // Test Server URl
export const WebSiteUrl = 'http://122.248.229.117:9000/#/'
export const WebSiteUrl2 = 'http://122.248.229.117:9000/#/'
export const ApplicationUrl = 'http://122.248.229.117:8090/'

export const PlayerOnly = '/PlayerOnly'
export const ClassRoom = '/ClassRoom'
export const SharedClassroom = '/SharedClassroom'


export const BASE_MEDIA_FOLDER = '/assets/media'
export const InitialPath = '/'
export const Login = '/Login'
export const Recovery = '/Recovery'
export const ResetPassword = '/ResetPassword'
export const PartnerRegistration = '/PartnerRegistration'
export const Contents = '/Contents'
export const MainDashboard = '/Contents/DashBoard'

//Admin
export const AdminDashboard = '/Contents/AdminUsers'
export const Settings = '/Contents/Settings'

// Product Head
export const ParameterDashboard = '/Contents/Parameters'
export const AssignTaskScreen = '/Contents/Tasks'
export const ProductHeadUsers = '/Contents/ProductHeadUsers'
export const PreviewLessonsDashboard = '/Contents/PreviewLessons'
export const AnaliticsDashboard = '/Contents/AnaliticsDashboard'
export const Player = '/Contents/Player'

// Content Creator
export const BacklogDashboard = '/Contents/Backlogs'
export const StatusDashboard = '/Contents/Status'

// Teacher
export const TeacherDashboard = '/Contents/ExploreLessons'

//Profile
export const ProfileDashboard = '/Contents/Profile'

// Experience Dashboard
export const ExperienceDashboard = '/Contents/ExperienceDashboard'
export const AddExperience = '/Contents/ExperienceDashboard/AddExperience'
export const DeviceList = '/Contents/ExperienceDashboard/DeviceList'
export const UpdateExperience = '/Contents/ExperienceDashboard/UpdateExperience'
export const MediaManagement = '/Contents/MediaManagement'

// Settings Dashboard
export const SettingsDashboard = '/Contents/SettingsDashboard'
export const SettingsChangePassword = '/Contents/SettingsDashboard/ChangePassword'

/// Internal Variables
export const Admin = 'Admin'
export const ProductHead = 'ProductHead'
export const ContentCreator = 'ContentCreator'
export const Teacher = 'Teacher'

// RazorPay key
export const Key = 'rzp_test_29V5QKQjJEuTpb'
