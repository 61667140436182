
import { Modal } from "react-bootstrap";

function PreViewLessonModal(props) {
    let url = `http://intbae.com/integrations/flutter/player?Id=${props.lessonID}`
    return (
        <Modal {...props} centered dialogClassName="modal-90w" contentClassName='modal-90w'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <iframe width="1280px" height="800px" src={url}  ></iframe>
            </Modal.Body>
        </Modal>
    );
}
export default PreViewLessonModal;