
import { useState } from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable'

function CreateLessonModal(props) {
    const  [isSelected, setIsSelected] = useState(true)

    // const classes = [
    //     { value: 'Class 1', label: 'Class 1' },
    //     { value: 'Class 2', label: 'Class 2' },
    //     { value: 'Class 3', label: 'Class 3' }
    //   ]
    //   const subject = [
    //     { value: 'Subject ', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    //   ]
    //   const lesson = [
    //     { value: '', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    //   ]
    //   const chapter = [
    //     { value: '', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    //   ]

      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted pink',
          color: isSelected ? 'red' : 'blue',
          padding: 20,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          width: 500,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
      }
   
    return (
        <Modal {...props} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Lesson
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form className="create-user-form" onSubmit={props.formSubmit}>
                    <div className="form-group d-flex">
                        <label className="form-label">Class:</label>
                        {/* <CreatableSelect 
                            isClearable
                            placeholder="Class"
                            options={classes}
                            customStyles={customStyles}
                        /> */}
                        <input className="form-control" type="text" onChange={props.addClass} autoFocus required />
                    </div>
                    <div className="form-group d-flex">
                        <label className="form-label">Subject:</label>
                        {/* <CreatableSelect 
                            isClearable
                            placeholder="Subject"
                            options={classes}
                            customStyles={customStyles}
                        /> */}
                        <input className="form-control" type="text"  onChange={props.addSubject} required />
                    </div>
                    <div className="form-group d-flex">
                        <label className="form-label">Lesson:</label>
                        {/* <CreatableSelect 
                            isClearable
                            placeholder="Lesson"
                            options={classes}
                            customStyles={customStyles}
                        /> */}
                        <input className="form-control" type="text"  onChange={props.addLesson} required />
                    </div>
                    <div className="form-group d-flex">
                        <label className="form-label">Chapter:</label>
                        {/* <CreatableSelect 
                            isClearable
                            placeholder="Chapter"
                            options={classes}
                            customStyles={customStyles}
                        /> */}
                        <input className="form-control" type="text"  onChange={props.addChapter} required />
                    </div>
                    <div className="form-group d-flex">
                        <label className="form-label">Assign:</label>
                        <select name="form-control" onChange={(e) => props.assignedTo(e)}>
                            <option value="">Select</option>
                            {
                                props.contentCreators.map((contentCreator, index) => {
                                    return (
                                        <option value={contentCreator.Id}>{contentCreator.FirstName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                   
                    <div className="form-submit d-flex justify-content-center">
                        <button className="btn-submit" type="submit">Submit</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
export default CreateLessonModal;