import React from "react";
import Axios from 'axios'

import * as Constants from '../../assets/helpers/constants'
import Player from "../Player/player";
import Swal from "sweetalert2";


class SharedClassroom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            authorized: false,
            message: "",
            studentId: null,
            classId: null,
            timeDiff: 0,
        }
    }

    onReceivingMessage = (e, classId = this.state.classId, studentId = this.state.studentId) => {
        console.log("Received message in Shared ClassRoom Revvster", e)
        if (e.data && e.data.includes("LESSON_COMPLETED")) {
            console.log("Lesson_Completed Action triggered in shared Classroom Revvster")
            console.log(classId, studentId)
            window.parent?.postMessage({
                info: e.data,
                classId: classId,
                studentId: studentId
            }, Constants.WebSiteUrl);
            window.parent?.postMessage({
                info: e.data,
                classId: classId,
                studentId: studentId
            }, Constants.WebSiteUrl2);
        }

        if (e.data === "LESSON_TERMINATED") {
            console.log("Lesson_Terminated Action triggered in shared Classroom")
            // window.parent?.postMessage('LESSON_COMPLETED', "http://localhost:3001");
            console.log("Lesson_Terminated Action triggered in shared Classroom Revvster. Clossing Lesson")
            window.parent?.postMessage('LESSON_TERMINATED', Constants.WebSiteUrl);
            window.parent?.postMessage('LESSON_TERMINATED', Constants.WebSiteUrl2);
        }
    }


    componentDidMount() {
        window.addEventListener("message", (e) => this.onReceivingMessage(e))

        const urlParams = window.location.href
        let values = urlParams.split(Constants.SharedClassroom + "/")
        if (values[1]) {
            this.getDetailsFromToken(values[1])

            this.countDown = setInterval(() => this.state.timeDiff !== 0 ? this.setState(prevState => {
                return {
                    timeDiff: prevState.timeDiff - 1
                }

            }) : this.setState({ message: "" }), 1000);

        } else {
            this.setState({
                authorized: false
            })
        }
        window.addEventListener('beforeunload', this.reloadHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.reloadHandler);
    }

    reloadHandler(e) {
        e.preventDefault()
        console.log('reload')
        return e.returnValue = "Are you sure you want to exit?";
    }

    getDetailsFromToken = (token) => {
        let config = {
            headers: {
                authorization: "bearer " + token,
            }
        }
        let compressedWidth = 0;
        let compressedHeight = 0;
        const aspectRatio = 1.777777778;

        let windowWidth = window.innerWidth * 0.85 * 0.75;
        let windowHeight = window.innerHeight * 0.87 * 0.85;
        console.log("windowWidth / windowHeight", windowWidth / windowHeight)
        if (windowWidth / windowHeight < aspectRatio) {
            compressedWidth = windowWidth;
            compressedHeight = windowWidth / aspectRatio;
        }
        else {
            compressedWidth = windowHeight * aspectRatio;
            compressedHeight = windowHeight;

        }


        Axios.get(Constants.BASE_URL + `api/decodeClassRoomToken`, config).then((result) => {
            if (result.data.status) {
                this.setState({
                    authorized: true,
                    message: "",
                    // url: `https://lessons.baethoedu.com?token=${token}&width=${compressedWidth}&height=${compressedHeight}`,
                    url: `http://intbae.com/integrations/flutter/player?token=${token}&width=${compressedWidth}&height=${compressedHeight}`,
                    studentId: result.data.studentId,
                    classId: result.data.classId
                })
            } else {
                this.setState({
                    authorized: true,
                    message: result.data.message,
                    // url: `https://lessons.baethoedu.com?token=${token}&width=${compressedWidth}&height=${compressedHeight}`,
                    url: `http://intbae.com/integrations/flutter/player?token=${token}&width=${compressedWidth}&height=${compressedHeight}`,
                    studentId: result.data.studentId,
                    classId: result.data.classId,
                    timeDiff: result.data.timeDiff
                })
            }
        })
    }

    render() {
        return (
            <div>
                {/* SHared classroom === {this.state.authorized ? "true" : "false"} */}
                {this.state.authorized && this.state.message === "" && <Player url={this.state.url} type='onlyPlayer' />}
                {this.state.message !== "" && this.state.timeDiff < 5 && <h4 className="loginMessage">User has already logged in. Please wait for {this.state.timeDiff} seconds to join.</h4>}

                {/* ? <h1>User has already logged in. Please wait for {this.state.timeDiff} seconds to join. </h1> : <h1>{this.state.message}</h1>} */}

            </div>
        )
    }
}

export default SharedClassroom