import React from 'react';

function Header(props) {
    return (
        <div className="HeaderSection mt-3 pr-3">
            <div className='row'>
                <div className='col-6 text-left'>
                    <p className='header-title'>{props.title}</p>
                </div>
                <div className="col-6 rigthAlign d-flex align-items-baseline justify-content-end gap-5" >
                    <p className='profile-name'>Welcome {props.name}</p>
                    <button type="button" className='logoutBtn noOutline noBorder' onClick={props.logout}>Logout</button>
                </div>
            </div>
        </div>
    )
}

export default Header